import { Select } from "antd";
import { TFeatureWorkflowInstance } from "entity-app/models";

import useWorkflowTemplateHook from "./use-workflow-template-hook";

function ProjectWorkflowTemplateDropdown(props: {
  featureId: number;
  featureWorkflowInstance: TFeatureWorkflowInstance;
  actions: { updateFeatureInstanceWorkflowTemplate: Function };
}) {
  const { featureId, featureWorkflowInstance, actions } = props;
  const {
    options,
    loading,
    selectedMaterialTemplateId,
    setSelectedMaterialTemplateId,
    applyingMDBTemplate,
    cannotChangeTemplate,
    isWFStarted
  } = useWorkflowTemplateHook({ featureId, featureWorkflowInstance });

  const onTemplateSelectChange = (templateId: string) => {
    setSelectedMaterialTemplateId(templateId);
    actions.updateFeatureInstanceWorkflowTemplate(templateId);
  };

  return (
    <Select
      options={options}
      value={options.length ? selectedMaterialTemplateId : null}
      onChange={onTemplateSelectChange}
      disabled={cannotChangeTemplate || isWFStarted || applyingMDBTemplate}
      loading={applyingMDBTemplate || loading}
      placeholder="Select a template"
    />
  );
}

export default ProjectWorkflowTemplateDropdown;
