import { GridApi } from "ag-grid-community";

export const resetGridFilters = (props: {
  setListFilter: any;
  api: GridApi | undefined;
}) => {
  const { setListFilter, api } = props;
  try {
    const currentFilters: any = api?.getFilterModel();
    const currentFilterCols = Object.keys(currentFilters);
    currentFilterCols.forEach((col: string) => {
      api?.destroyFilter(col);
    });

    setTimeout(() => {
      setListFilter(null);
      api?.onFilterChanged();
    });
  } catch (ex) {
    //
  }
};
