import { Button, Collapse, Modal, message } from "antd";
import WorkflowTemplateBulkEditor from "components/workflow-template-bulk-editor";
import { transformMilestone } from "components/workflow-template-bulk-editor/data-transformations";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { useContext, useMemo, useState } from "react";
import { UPDATE_PROJECT_FEATURE_DURATIONS } from "services/graphQL/mutations";
import { EWorkflowStatusDataBlock } from "../../constants";
import DisplayNameListPopover from "./display-name-list";

const { Panel } = Collapse;

function PanelItem(props: any) {
  const { template } = props;

  const { gqlClientForProject }: TProjectContext = useContext(ProjectContext);

  const [mutationBulkUpdateMaterials] = useCIQMutation(
    UPDATE_PROJECT_FEATURE_DURATIONS,
    {
      client: gqlClientForProject
    }
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const transformedMilestones = useMemo(() => {
    return transformMilestone(template.project_template_milestones);
  }, [template.project_template_milestones]);

  const incomingSameFloatValueAcrossMaterials = useMemo(() => {
    let hasSameValues = true;
    const firstFloatValue = template.materials[0].float;
    template.materials.forEach((material: any) => {
      const floatValue = material.float;
      if (floatValue !== firstFloatValue) {
        hasSameValues = false;
      }
    });
    if (hasSameValues) {
      return firstFloatValue;
    }

    return null;
  }, [template.materials]);

  const [float, setFloat] = useState(
    incomingSameFloatValueAcrossMaterials || ""
  );

  const incomingMilestones = useMemo(() => {
    return transformedMilestones.map((milestone: any, index: number) => {
      let hasSameValues = true;
      const firstOffsetValue =
        template.materials[0].date_block_data[`offset_${index + 1}`];
      template.materials.forEach((material: any) => {
        const offsetValue = material.date_block_data[`offset_${index + 1}`];
        if (offsetValue !== firstOffsetValue) {
          hasSameValues = false;
        }
      });

      const milestoneToReturn = { ...milestone };

      if (hasSameValues) {
        milestoneToReturn.value = firstOffsetValue;
      }

      return milestoneToReturn;
    });
  }, [template.materials, transformedMilestones]);

  const [milestones, setMilestones] = useState(incomingMilestones);

  const onCancelEdit = () => {
    setMilestones(incomingMilestones);
    setFloat(incomingSameFloatValueAcrossMaterials || "");
    setIsEditing(false);
  };

  const onSave = async (event: any) => {
    setIsSaving(true);
    event.stopPropagation();

    const materialsIds = template.materials.map((material: any) => material.id);

    const offsets: any = {};
    milestones.forEach((milestone: any) => {
      if (milestone.value)
        offsets[`offset_${milestone.sequence_no}`] = milestone.value;
    });

    const payload: any = {
      featureData: {
        materials: materialsIds,
        offsets,
        workflow_template_id: template.id
      }
    };

    if (float) {
      payload.featureData.float = float;
    }

    const bulkEditResponse = await mutationBulkUpdateMaterials({
      variables: payload
    });
    // console.log("bulkEditResponse ", bulkEditResponse);
    if (bulkEditResponse.success) {
      setIsEditing(false);
      message.success("Updated successfully.");
    }

    setIsSaving(false);
  };

  const panelHeader = (
    <div className="flex items-center justify-between">
      <div className="uppercase !text-gray-600">
        {template.name} (
        <span className="-ml-1">
          <DisplayNameListPopover
            list={template.materials.map((material: any) => {
              return { name: material.name };
            })}
          >
            {template.materials.length}
          </DisplayNameListPopover>
        </span>{" "}
        <span className="-ml-1.5">Materials</span>)
      </div>
      <div>
        {!isEditing && (
          <Button
            type="primary"
            size="small"
            className="px-4"
            onClick={(event) => {
              event.stopPropagation();
              setIsEditing(true);
            }}
          >
            Edit
          </Button>
        )}
        {(isEditing || isSaving) && (
          <div className="space-x-3">
            <Button
              type="primary"
              size="small"
              className="px-4"
              onClick={onSave}
              disabled={isSaving}
              loading={isSaving}
            >
              Save
            </Button>
            <Button
              size="small"
              className="px-4"
              onClick={(event) => {
                event.stopPropagation();
                onCancelEdit();
              }}
              disabled={isSaving}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  let panelProps = {
    ...props
  };
  if (isEditing) {
    panelProps = {
      ...panelProps
      // collapsible: "disabled",
      // onItemClick: null
    };
  }

  return (
    <Panel {...panelProps} header={panelHeader} key={template.id}>
      <WorkflowTemplateBulkEditor
        disabled={!isEditing}
        milestonesState={[milestones, setMilestones]}
        floatState={[float, setFloat]}
        entity="material"
      />
    </Panel>
  );
}

type PropsEditDuration = {
  showEdit: boolean;
  onUpdateDone: () => void;
  selectedRows: any[];
  materials: any;
  MDBTemplates: null | Array<any>;
};

function BulkEditMaterialDurations(props: PropsEditDuration) {
  const { showEdit, onUpdateDone, selectedRows, materials, MDBTemplates } =
    props;

  const selectedMaterials = useMemo(() => {
    return selectedRows
      .map((row: any) => {
        return materials.find((material: any) => {
          return row.id === material.id;
        });
      })
      .filter((material: any) => {
        return (
          material.date_block_data.workflow_status !==
          EWorkflowStatusDataBlock.WF_COMPLETED
        );
      });
  }, [materials, selectedRows]);

  const templatesWithMaterials = useMemo(() => {
    if (!MDBTemplates) return null;
    const updatedTemplates = MDBTemplates.map((mapTemplate: any) => {
      const template: any = { ...mapTemplate };
      template.materials = [];
      selectedMaterials.forEach((row: any) => {
        if (template.id === row.date_block_template_id) {
          template.materials.push(row);
        }
      });
      return template;
    });
    return updatedTemplates.filter((template) => template.materials.length);
  }, [MDBTemplates, selectedMaterials]);

  return (
    <Modal
      title="BULK EDIT DURATIONS"
      className="custom-drawer with-padding-fix"
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ minHeight: "calc(100vh - 148px)" }}
      width="calc(100vw - 200px)"
      footer={[
        <div className="flex w-full space-x-3 justify-end" key={100}>
          <Button
            onClick={() => {
              onUpdateDone();
            }}
            key={101}
          >
            Close
          </Button>
        </div>
      ]}
      open={showEdit}
      onCancel={() => {
        onUpdateDone();
      }}
      destroyOnClose
      keyboard={false}
    >
      <div className="px-2 -mt-2">
        <div className="font-semibold">
          <span className="tracking-wide">Selected Materials:</span>{" "}
          {selectedRows.length}
        </div>

        {templatesWithMaterials && templatesWithMaterials?.length !== 0 && (
          <div className="mt-2">
            Durations for completed milestones will not be updated.
          </div>
        )}
        {templatesWithMaterials && templatesWithMaterials?.length === 0 && (
          <div className="mt-2 font-semibold">
            Durations cannot be edited as all the selected materials are closed.
          </div>
        )}

        {templatesWithMaterials?.length !== 0 && (
          <div className="mt-4">
            <Collapse className="ciq-collapse" expandIconPosition="end">
              {templatesWithMaterials?.map((template: any) => {
                return <PanelItem template={template} key={template.id} />;
              })}
            </Collapse>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default BulkEditMaterialDurations;
