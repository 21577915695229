import { Form, InputNumber, message, Select } from "antd";
import { CompanyParticipationType } from "constants/index";
import { useProjectParticipants } from "hooks/project-participants";
import { useEffect } from "react";
import { restrictInputToNumbers } from "utils/inpututils";

function Participants(props: { actions?: any; featureInstanceData?: any }) {
  const { actions, featureInstanceData } = props;
  const [form] = Form.useForm();
  const { projectParticipants } = useProjectParticipants();
  const subContractors = projectParticipants?.responsibleContractors;

  useEffect(() => {
    if (!featureInstanceData) return;
    const awardedSubContractor =
      featureInstanceData?.feature_company_participants?.find(
        (company: any) =>
          company?.company_participation_type_id ===
          CompanyParticipationType.AWARDED_SUB_CONTRACTOR
      );

    const biddingSubContractor =
      featureInstanceData?.feature_company_participants?.filter(
        (company: any) =>
          company?.company_participation_type_id ===
          CompanyParticipationType.BIDDING_SUB_CONTRACTOR
      );

    form.setFieldsValue({
      no_of_bidders: featureInstanceData?.feature_bid_package?.no_of_bidders,
      estimated_value:
        featureInstanceData?.feature_bid_package?.estimated_value,
      awarded_value: featureInstanceData?.feature_bid_package?.awarded_value,
      bidding_subcontractors: biddingSubContractor?.map(
        (c: any) => c?.vendor_id
      ),
      awarded_subcontractor: awardedSubContractor?.vendor_id
    });
  }, [featureInstanceData, form]);

  const onInsert = async (
    value: any,
    companyParticipantTypeId: CompanyParticipationType
  ) => {
    const response: any = await actions.insertFeatureCompanyParticipant({
      vendorId: value,
      companyParticipantTypeId
    });
    if (response?.success) {
      message.success("Updated successfully.");
    }
  };

  const onDelete = async (
    value: any,
    companyParticipantTypeId: CompanyParticipationType
  ) => {
    const response: any = await actions.deleteFeatureCompanyParticipant({
      vendorId: value,
      companyParticipantTypeId
    });
    if (response?.success) {
      message.success("Updated successfully.");
    }
  };

  const updateAwardedSubcontractor = async (selectedCompanyId: string) => {
    const awardedSubContractor =
      featureInstanceData?.feature_company_participants?.find(
        (company: any) =>
          company?.company_participation_type_id ===
          CompanyParticipationType.AWARDED_SUB_CONTRACTOR
      );

    if (!awardedSubContractor) {
      onInsert(
        selectedCompanyId,
        CompanyParticipationType.AWARDED_SUB_CONTRACTOR
      );
    } else {
      const response: any = await actions.deleteFeatureCompanyParticipant({
        vendorId: awardedSubContractor?.vendor_id,
        companyParticipantTypeId:
          CompanyParticipationType.AWARDED_SUB_CONTRACTOR
      });
      if (response?.success) {
        onInsert(
          selectedCompanyId,
          CompanyParticipationType.AWARDED_SUB_CONTRACTOR
        );
      }
    }
  };

  const updateParticipantInputFields = async (key: string, value: any) => {
    const param: any = {};
    param[key] = value;
    const response = await actions.updateBidPackageFields(param);
    if (!response.success) {
      form.setFieldsValue({ key: value });
    }
  };

  return (
    <Form layout="vertical" form={form}>
      <Form.Item label="Number of Bidders" name="no_of_bidders">
        <InputNumber
          className="w-full"
          type="number"
          min={0}
          onKeyDown={restrictInputToNumbers}
          onBlur={(event: any) =>
            updateParticipantInputFields("no_of_bidders", event?.target?.value)
          }
        />
      </Form.Item>
      <Form.Item label="Bidding Subcontractors" name="bidding_subcontractors">
        <Select
          mode="multiple"
          options={
            subContractors
              ? subContractors.map((company: any) => ({
                  label: company.subscription_vendor.name,
                  value: company.vendor_id
                }))
              : []
          }
          onSelect={(value: any) => {
            onInsert(value, CompanyParticipationType.BIDDING_SUB_CONTRACTOR);
          }}
          onDeselect={(value: any) => {
            onDelete(value, CompanyParticipationType.BIDDING_SUB_CONTRACTOR);
          }}
          showArrow
        />
      </Form.Item>
      <div className="flex space-x-2 w-full">
        <Form.Item
          className="grow"
          label="Estimated Value"
          name="estimated_value"
        >
          <InputNumber
            className="w-full"
            addonBefore="$"
            min={0}
            onKeyDown={restrictInputToNumbers}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            onBlur={(event: any) =>
              updateParticipantInputFields(
                "estimated_value",
                event?.target?.value?.replaceAll(",", "")
              )
            }
          />
        </Form.Item>
        <Form.Item className="grow" label="Awarded Value" name="awarded_value">
          <InputNumber
            className="w-full"
            addonBefore="$"
            min={0}
            onKeyDown={restrictInputToNumbers}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            onBlur={(event: any) => {
              updateParticipantInputFields(
                "awarded_value",
                event?.target?.value?.replaceAll(",", "")
              );
            }}
          />
        </Form.Item>
      </div>
      <Form.Item label="Awarded Subcontractor" name="awarded_subcontractor">
        <Select
          options={
            subContractors
              ? subContractors.map((company: any) => ({
                  label: company.subscription_vendor.name,
                  value: company.vendor_id
                }))
              : []
          }
          onChange={updateAwardedSubcontractor}
        />
      </Form.Item>
    </Form>
  );
}

export default Participants;
