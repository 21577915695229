/* eslint-disable react/no-unstable-nested-components */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  ICellRendererParams,
  ValueFormatterParams,
  CsvExportParams,
  SideBarDef
} from "ag-grid-community";
import "./material-list.css";
import { Spin, message, notification } from "antd";
import MaterialCreateComponent from "pages/material-create";
import "../../index.css";

import { GridLoadingIndicator } from "components/widgets";
import { EditOutlined, LinkOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  isPermissionNotGrantted,
  ProjectContext,
  TProjectContext
} from "context/ProjectProvider";
import {
  MUTATION_SAVE_COLUMNS,
  MUTATION_UPDATE_MANY_NEW_MATERIAL_DATBLOCK,
  MUTATION_UPDATE_MATERIAL_PK
} from "services/graphQL/mutations";
import SpecSectionCellEditor from "components/cell-editor/spec-section-editor";
import {
  dateCellRenderer,
  gcRepresentativeCellRenderer,
  nextDeadlineCellRenderer,
  responsibleContractorCellRenderer,
  specSectionRenderer,
  SubmittalLinksCellRenderer,
  TaskLinksCellRenderer,
  userOrgCellRenderer,
  materialDueDateHeaderComponent,
  responsibleContractorCellRendererWithCustomEdit,
  userOrgCellRendererWithCustomEdit,
  gcRepresentativeCellRendererWithCustomEdit,
  logStatusCellRenderer
} from "components/cell-renders";
import { matchPath, useHistory, useParams } from "react-router";
import { FilterItem } from "models/types";
import ImportMaterialLogs from "components/import-material-logs";
import { Link } from "react-router-dom";
import { TMaterialItem } from "models/materials";
import { AppContext } from "context/AppProvider";

import { useProjectParticipants } from "hooks/project-participants";
import ResponsibleContractorCellEditor from "components/cell-editor/responsible-contractor-cell-editor";
import { MaterialAssigneeSelectCellEditor } from "components/cell-editor/material-assignee-select-cell-editor";
import { UnitType } from "pages/material-details/material-details-tab/units-tree";
import {
  QUERY_GET_MDB_TEMPLATES,
  QUERY_MASTER_UNITS,
  QUERY_SAVED_COLUMN_HEADERS
} from "services/graphQL/queries";
import { matchUserProjectRole } from "utils/utils";
import { MUTATION_UPDATE_DATEBLOCK_BY_MATERIALID } from "components/date-block/gql-graphgl";
import { MaterialGCRepresentativeSelectCellEditor } from "components/cell-editor/material-gc-representative-cell-editor";
import WarrantyCellEditor from "components/cell-editor/warranty-cell-editor";
import { getLoggedinUserId } from "services/auth";
import { applyMaterialLogFilters } from "utils/ag-grid-programmatic-filtering";
import MaterialExtraction from "components/material-extraction";
import { NotificationPlacement } from "antd/lib/notification";
import { useCIQLazyQuery, useCIQQuery } from "hooks/ciq-gql-hooks";
import numberCellEditor from "components/cell-editor/numberCellEditor";
import { MaterialTemplateSelectCellEditor } from "components/cell-editor/material-template-select-cell-editor";

import {
  getMaxNumberOfMilestoneFromProjectTemplate,
  getMilestoneColumns
} from "components/milestones-columns";

import BulkEditMaterialDurations from "components/bulk-edit-material/Bulk-edit-material-durations";
import BulkEditMaterialDetails from "components/bulk-edit-material/bulk-edit-material-details";
import { TAuditChangeLog } from "change-events/change-event-polling";
import {
  EUserTypes,
  ErrorMessages,
  MAX_NUMBER_OF_FETCH_ITEM,
  MaterialNotReleaseStatus,
  MaterialOffsetFieldsMappingOldToNew,
  ProjectPermissionEnum,
  materialLogFilterTitles
} from "../../constants/index";
import MaterialListFilterBar from "./filter-bar";

import CustomDateFilters from "../submittals-list/custom-date-filter";
import { DateFilter, DateUtils } from "../../utils/dateutils";
import { measurementSelectEditor } from "./cell-editors";
import { TSaveColumnState } from "../../components/column-config-views";
import { useCiqMaterialList } from "./hooks/material";
import { MATERIAL_LIST_PERF } from "./queries/material-queries";

const editableValueRenderer = ({ value, data, context, node, column }: any) => {
  return (
    <div className="w-full max-w-full flex items-center">
      <div className="grow overflow-hidden truncate h-[40px]">{value}</div>
      <EditOutlined
        className="cell-edit-icon"
        onClick={() => {
          context.onEditCell({
            rowIndex: node.rowIndex!,
            colKey: column!.getId(),
            data
          });
        }}
      />
    </div>
  );
};

export const materialCellRenderer = ({
  data,
  context,
  projectId,
  node,
  column
}: any) => {
  return (
    <>
      <div className="pl-3 !flex !items-center">
        <Link
          className="gridIdLink grow truncate"
          to={{
            pathname: `/project/${projectId}/materials/${data.id}`
          }}
        >
          {data.name || <>&nbsp;</>}
        </Link>
        <EditOutlined
          className="cell-edit-icon px-1"
          onClick={() => {
            context.onEditCell({
              rowIndex: node.rowIndex!,
              colKey: column!.getId()
            });
          }}
        />
      </div>
      {data.description && (
        <p className="colSecondValue pl-3">{data.description || <>&nbsp;</>}</p>
      )}
    </>
  );
};

function Materials() {
  const history = useHistory();
  const { projectId } = useParams() as any;

  const {
    gqlClientForProject,
    tokenContents,
    projectDetails,
    columnHeaders: { materialHeaderMap },
    eventLogs
  }: TProjectContext = useContext(ProjectContext);

  const { materialListFilter, setMaterialListFilter }: any =
    useContext(AppContext);

  const gridRef = useRef<AgGridReact<any>>(null);
  const [showNewDrawer, setShowNewDrawer] = useState(false);
  const [showExtractionPanel, setShowExtractionPanel] = useState(false);
  const [showImportLogDrawer, setShowImportLogDrawer] = useState(false);

  const [stats, setStats] = useState<any>("");
  const [isGridReady, setGridReady] = useState(false);
  const [gridSearchtext, setGridSearchtext] = useState("");
  const [customDateFilter, setCustomDateFilter] = useState<any>({});
  const [filters, setFilters] = useState<FilterItem[]>([]);
  const [displayRowCount, setDisplayRowCount] = useState(0);
  const [maxNumberOfMilestone, setMaxNumberOfMilestone] = useState({
    isLastMileStone: false,
    numberOfMaxMileStones: 0
  });
  const [isFirstDataRendered, setIsFirstDataRendered] = useState(false);
  const clearFilterRef = useRef(false);

  const [showBulkEditMaterialDetails, setShowBulkEditMaterialDetails] =
    useState(false);
  const [showBulkEditMaterialDurations, setShowBulkEditMaterialDurations] =
    useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState<any[]>([]);
  const isCurrentUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const [gridData, setGridData] = useState<{
    projectMaterials?: any;
  }>({
    projectMaterials: null
  });

  const existingMaterialsCountRef = useRef<any>();
  const [api, contextHolder] = notification.useNotification();

  const canInlineEditMaterialGeneralInfoAndParticipants: any =
    !isPermissionNotGrantted(
      ProjectPermissionEnum.EditMaterialGeneralInfoAndParticipants,
      tokenContents?.role!
    );

  const canInlineEditMaterialCharacteristics: any = !isPermissionNotGrantted(
    ProjectPermissionEnum.EditMaterialCharacteristics,
    tokenContents?.role!
  );

  const canInlineEditMaterialDBOffset: any = !isPermissionNotGrantted(
    ProjectPermissionEnum.EditMaterialDBOffset,
    tokenContents?.role!
  );

  // console.log("userCanChangeFloat ", userCanChangeFloat);

  const genInfoAndParticipantsCellsEditable = (params: any) => {
    return params.context.canInlineEditMaterialGeneralInfoAndParticipants;
  };

  const materialCharacteristicsCellsEditable = (params: any) => {
    return params.context.canInlineEditMaterialCharacteristics;
  };

  const canChangeMaterialDBTemplate = useCallback(
    (params: any) => {
      const materialTemplateChangePermitted = !isPermissionNotGrantted(
        ProjectPermissionEnum.ChangeMaterialTemplate,
        tokenContents?.role!
      );
      const isMaterialWorkflowStarted =
        params.data?.status?.toLowerCase() !==
        MaterialNotReleaseStatus.toLowerCase();
      return materialTemplateChangePermitted && !isMaterialWorkflowStarted;
    },
    [tokenContents?.role]
  );

  const canChangeMaterialDBFloat = useCallback(
    (params: any) => {
      const floatChangePermitted = !isPermissionNotGrantted(
        ProjectPermissionEnum.EditMaterialDBOffset,
        tokenContents?.role!
      );
      const isMaterialWorkflowStarted =
        params.data?.status?.toLowerCase() !==
        MaterialNotReleaseStatus.toLowerCase();
      return floatChangePermitted && !isMaterialWorkflowStarted;
    },
    [tokenContents?.role]
  );

  const { data: allUnits } = useQuery<{
    material_quantity_unit: Array<UnitType>;
    material_size_unit: Array<UnitType>;
  }>(QUERY_MASTER_UNITS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const isLoggedInUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const { projectParticipants, getSubmittalUserById, getSubmittalCompanyById } =
    useProjectParticipants(!isLoggedInUserGC);

  const { data: projectMaterials, refetch: refetchMaterialLog } =
    useCiqMaterialList<any>({
      gqlClientForProject
    });

  const [getLazyMaterialData] = useCIQLazyQuery(MATERIAL_LIST_PERF);

  const getAndUpdateMaterialList = useCallback(
    async (materialIds: Array<string>) => {
      if (!gridData?.projectMaterials) return;

      if (materialIds.length >= MAX_NUMBER_OF_FETCH_ITEM) {
        refetchMaterialLog();
        return;
      }

      const variables = { where: { id: { _in: materialIds } } };

      const res = await getLazyMaterialData(variables);
      if (res?.success && res.data?.data) {
        const updatedMaterialList = res.data.data.material_list_func;

        const items = ([...gridData.projectMaterials] || []) as Array<any>;
        updatedMaterialList.forEach((item: any) => {
          const foundIndex = items.findIndex((x) => x.id === item.id);
          if (foundIndex === -1) items.push(item);
          items[foundIndex] = item;
        });
        setGridData({
          projectMaterials: items
        });
      }
    },
    [getLazyMaterialData, gridData.projectMaterials, refetchMaterialLog]
  );

  const deleteMaterialsFromList = useCallback(
    (ids: Array<string>) => {
      const items = gridData.projectMaterials
        ? [...gridData.projectMaterials]
        : [];
      ids.forEach((id) => {
        const index = items.findIndex((x) => x.id === id);
        items.splice(index, 1);
      });
      setGridData({
        projectMaterials: items
      });
    },
    [gridData.projectMaterials]
  );

  const { data: MDBTemplatesData, refetch: refetchMDB } = useCIQQuery(
    QUERY_GET_MDB_TEMPLATES,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      if (
        eventLogs.some((x) => x.data_source === "project_workflow_templates")
      ) {
        refetchMDB(); // Refetching Material Template data
      }
      const isImportLogCSV = (log: TAuditChangeLog) =>
        log.change_type === "import" && log.data_source === "material";

      if (eventLogs.some(isImportLogCSV)) {
        refetchMaterialLog(); // ReFetch All Material Logs.
        return;
      }

      const conditionForMaterialLog = (log: TAuditChangeLog) =>
        log.info.computation !== false &&
        log?.info?.material_ids &&
        log?.info?.material_ids?.length > 0;
      if (eventLogs.some(conditionForMaterialLog)) {
        const auditLogs = eventLogs.filter(
          (log: TAuditChangeLog) => log.info.material_ids
        ) as Array<TAuditChangeLog>;
        const deletedIds = auditLogs
          .filter((log) => ["delete", "bulk_delete"].includes(log.change_type))
          .map((s: TAuditChangeLog) => s.info.material_ids!) as any;
        if (deletedIds.length) {
          const deletedDArray = [...new Set([].concat([], ...deletedIds))];
          deleteMaterialsFromList(deletedDArray);
        }

        const ids = auditLogs
          .filter((log) => !["delete", "bulk_delete"].includes(log.change_type)) // update create import
          .map((s) => s.info.material_ids) as any;
        if (ids.length) {
          const singleDArray = [...new Set([].concat([], ...ids))];
          getAndUpdateMaterialList(singleDArray);
        }
      }
    }
    previousEventLogs.current = eventLogs;
  }, [
    deleteMaterialsFromList,
    eventLogs,
    getAndUpdateMaterialList,
    refetchMDB,
    refetchMaterialLog
  ]);

  const MDBTemplates = useMemo(() => {
    if (!MDBTemplatesData) return null;
    return MDBTemplatesData.project_workflow_templates;
  }, [MDBTemplatesData]);

  const MDBTemplatesDropdownOptions = useMemo(() => {
    if (!MDBTemplates) return null;
    return MDBTemplates.map((template: any) => {
      return {
        label: template.name,
        value: template.id
      };
    });
  }, [MDBTemplates]);

  // console.log("MDBTemplates ", MDBTemplates);

  const [updateNewDateBlock] = useMutation(
    MUTATION_UPDATE_DATEBLOCK_BY_MATERIALID,
    {
      client: gqlClientForProject
    }
  );

  const [updateMaterial] = useMutation<any>(MUTATION_UPDATE_MATERIAL_PK, {
    client: gqlClientForProject
  });

  const [updateMDB] = useMutation(MUTATION_UPDATE_MANY_NEW_MATERIAL_DATBLOCK, {
    client: gqlClientForProject
  });

  // TODO: Commented out subscription code due to loop calls on filterchange events. will refactor in feature.
  // const { query: qColumnHeaders, sub: sColumnHeaders } = useMemo(() => {
  //   return getGQL(SAVED_COLUMN_HEADERS);
  // }, []);
  // const { data: savedColumnsData } = useQuerySubscription(
  //   qColumnHeaders,
  //   sColumnHeaders,
  //   {
  //     client: gqlClientForProject,
  //     skip: !gqlClientForProject || !getLoggedinUserId(),
  //     variables: {
  //       where: { user_id: { _eq: getLoggedinUserId() } }
  //     }
  //   }
  // );

  const { data: savedColumnsData } = useQuery(QUERY_SAVED_COLUMN_HEADERS, {
    fetchPolicy: "no-cache",
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    variables: {
      where: { user_id: { _eq: getLoggedinUserId() } }
    }
  });

  const milestonesColumns = useMemo(
    () => getMilestoneColumns({ setCustomDateFilter }),
    []
  );
  const calculateMaxNumberOfMilestone = useCallback(() => {
    if (!gridRef.current) return;
    const displayingTemplateIds = [] as Array<string>;
    for (
      let index = 0;
      index < gridRef?.current?.api?.getDisplayedRowCount();
      index = 1 + index
    ) {
      const element = gridRef?.current?.api?.getDisplayedRowAtIndex(index);
      if (element) {
        displayingTemplateIds.push(element.data.date_block_template_id);
      }
    }

    if (MDBTemplates) {
      const displayTemplates = MDBTemplates.filter((m: any) =>
        displayingTemplateIds.includes(m.id)
      );
      const maxMileStone =
        getMaxNumberOfMilestoneFromProjectTemplate(displayTemplates);
      setMaxNumberOfMilestone(maxMileStone);
      if (gridRef.current && maxMileStone)
        milestonesColumns.updateAllMileStoneInitialVisibility(
          gridRef.current?.columnApi,
          maxMileStone
        );
    }
  }, [MDBTemplates, milestonesColumns]);

  const [saveColumnsState] = useMutation<any>(MUTATION_SAVE_COLUMNS, {
    client: gqlClientForProject
  });

  const savedColumns = useMemo(() => {
    if (!savedColumnsData || !savedColumnsData?.project_users?.length)
      return null;

    const resArr: any[] = savedColumnsData?.project_users;
    const colConfig = resArr[resArr.length - 1];
    calculateMaxNumberOfMilestone();
    return {
      submittals: colConfig.submittal_column_config,
      materials: colConfig.material_column_config
    };
  }, [calculateMaxNumberOfMilestone, savedColumnsData]);

  useEffect(() => {
    if (!projectMaterials || !savedColumnsData) return;

    setGridData({
      projectMaterials: projectMaterials.material_list_func
    });
  }, [projectMaterials, savedColumnsData]);

  useEffect(() => {
    if (isGridReady && gridData.projectMaterials?.length === 0) {
      gridRef.current?.api?.hideOverlay();
      gridRef.current?.api?.showNoRowsOverlay();
    }
  }, [gridData.projectMaterials, isGridReady]);

  useEffect(() => {
    if (gridData.projectMaterials && gridData.projectMaterials.length) {
      setTimeout(() => {
        gridRef.current?.api.refreshCells({
          force: true,
          columns: ["assignee", "gc_representative"]
        });
      }, 0);
    }
  }, [gridData.projectMaterials]);

  const skippedColumns = useMemo(() => {
    const mileStoneColumnColIds = milestonesColumns.mileStoneColumns.map(
      (x) => x.colId
    );
    return ["action", ...mileStoneColumnColIds];
  }, [milestonesColumns.mileStoneColumns]);

  const getColumnStateFromGrid = () => {
    const colNameMap: any = {
      custom_spec_section: "spec_section_no",
      material_tag: "tag_name",
      quantity_unit: "quantity",
      material_size_unit: "material_size",
      linked_tasks: "linked_activities"
    };

    const gridRefObj = gridRef?.current;

    const colFilters: any = gridRefObj?.api?.getFilterModel();

    const columnState = gridRefObj?.columnApi
      ?.getColumnState()
      ?.map((column: any, index: number) => {
        if (skippedColumns.includes(column.colId)) return null;

        const colKey = column?.colId || "";
        const range: any = { ...colFilters[colKey]?.range } || null;

        if (range) {
          range.to = range.to ? `${range.to}` : null;
          range.from = range.from ? `${range.from}` : null;
        }

        const field = {
          name: colNameMap[colKey] || column.colId,
          filter: colFilters[colKey]?.values || null,
          sortOrder: column?.sort || null,
          range
        };
        return {
          order: index + 1,
          field,
          visibility: !column.hide
        };
      })
      .filter((c: any) => c);

    if (savedColumns?.materials?.columnList) {
      const sortedColArr = savedColumns?.materials?.columnList?.map(
        (col1: any) =>
          columnState?.find(
            (col2: any) => col2?.field.name === col1?.field.name
          )
      );

      return {
        columnList: sortedColArr,
        quickSearch: gridSearchtext
      };
    }
    return null;
  };

  const onSaveColumnState = async () => {
    console.log("onSaveColumnState ");
    const columnsStates = getColumnStateFromGrid();
    if (columnsStates) {
      try {
        const variables = {
          submittal_column_config: savedColumns?.submittals,
          material_column_config: columnsStates
        };
        const updateResponse: any = await saveColumnsState({
          variables
        });
        if (updateResponse.errors) {
          message.error({ content: updateResponse.errors[0].message });
        } else {
          // message.success("Updated successfully");
        }
      } catch (ex) {
        console.log("An error occured");
      }
    }
  };

  const setFilterStateFromData = useCallback(
    (materialColumns: TSaveColumnState) => {
      if (!materialColumns) return;
      gridRef?.current?.api?.setFilterModel(null);
      const filterstate: any = {};
      // setGridSearchtext(materialColumns.quickSearch || "");
      if (materialColumns.columnList?.length > 0) {
        const colNameMap: any = {
          spec_section_no: "custom_spec_section",
          tag_name: "material_tag",
          quantity: "quantity_unit",
          material_size: "material_size_unit",
          linked_activities: "linked_tasks"
        };

        const columnState: any = gridRef?.current?.columnApi
          ?.getColumnState()
          ?.map((column: any) => {
            if (skippedColumns.includes(column.colId)) return null;
            const colKey = column?.colId || "";
            const col1 = materialColumns.columnList?.find(
              (cols: any) =>
                colNameMap[cols.field.name] === colKey ||
                cols.field.name === colKey
            );

            const filter = col1?.field.filter;
            if (filter) {
              const range: any = { ...col1?.field?.range };
              if (range) {
                range.to = Number(range.to);
                range.from = Number(range.from);
              }
              filterstate[colKey] = {
                values: filter || null,
                filterType: "set",
                range
              };
            }

            const gridCol = { ...column };
            gridCol.hide = !col1?.visibility;
            gridCol.order = col1?.order;
            gridCol.sort = col1?.field.sortOrder;
            return gridCol;
          })
          .filter((c: any) => c);

        if (columnState) {
          const sortedArr = columnState?.sort(
            (a: any, b: any) => a.order - b.order
          );
          gridRef.current?.columnApi.applyColumnState({
            state: sortedArr,
            applyOrder: true
          });

          if (Object.keys(filterstate).length > 0) {
            setTimeout(() => {
              gridRef.current?.api?.setFilterModel(filterstate);
            }, 0);
          }
        }
      }
    },
    [skippedColumns]
  );

  useEffect(() => {
    if (isGridReady && savedColumns && savedColumns.materials)
      setFilterStateFromData(savedColumns.materials);
  }, [savedColumns, isGridReady, setFilterStateFromData]);

  useEffect(() => {
    return () => {
      const nextURLPath = window.location.pathname;
      const matchUrl = matchPath(nextURLPath, {
        path: "/project/:projectId/materials/:materialId",
        exact: true,
        strict: false
      });
      if (!matchUrl && materialListFilter) {
        setMaterialListFilter(null);
      }
    };
  }, [materialListFilter, setMaterialListFilter]);

  const onFirstDataRendered = useCallback(() => {
    if (materialListFilter) {
      applyMaterialLogFilters({ gridRef, materialListFilter });
    }
    calculateMaxNumberOfMilestone();

    if (savedColumns?.materials && materialListFilter === null) {
      setFilterStateFromData(savedColumns.materials);
    }
    gridRef.current?.api.onFilterChanged();
    setTimeout(() => {
      // NOTE: To load initial data once in onFilterApplied method
      setIsFirstDataRendered(true);
    }, 2000);
  }, [
    materialListFilter,
    calculateMaxNumberOfMilestone,
    savedColumns,
    setFilterStateFromData
  ]);

  const updateStats = useCallback(() => {
    if (isGridReady && gridData.projectMaterials) {
      const displayedRowCount: any =
        gridRef.current?.api?.getDisplayedRowCount();

      if (
        displayedRowCount === undefined ||
        gridData.projectMaterials?.length === undefined
      )
        return;

      if (materialListFilter) {
        const titleText = materialLogFilterTitles[materialListFilter];
        const statsContents = (
          <>
            <span>{`${displayedRowCount} Materials`} </span>
            <span className="text-xs font-normal">({titleText})</span>
          </>
        );
        setStats(statsContents);
        setDisplayRowCount(displayedRowCount || 0);
        return;
      }

      if (gridRef.current?.api?.isAnyFilterPresent()) {
        setTimeout(() => {
          const rowCount = gridRef?.current?.api?.getDisplayedRowCount();
          setStats(
            `${rowCount} of ${gridData.projectMaterials.length} Materials`
          );
          setDisplayRowCount(rowCount || 0);
        }, 500);
        return;
      }

      setStats(`${gridData.projectMaterials.length} Materials`);
      setDisplayRowCount(gridData.projectMaterials.length);
    }
  }, [gridData.projectMaterials, isGridReady, materialListFilter]);

  useEffect(() => {
    updateStats();
  }, [updateStats]);

  useEffect(() => {
    gridRef.current?.api?.setQuickFilter(gridSearchtext);
    updateStats();
  }, [gridSearchtext, updateStats]);

  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      editable: false,
      resizable: true,
      filter: false,
      menuTabs: [],
      sortingOrder: ["asc", "desc"],
      suppressColumnsToolPanel: false
    };
  }, []);

  const customComparator = (valueA: any, valueB: any) => {
    return (valueA || "")
      .toLowerCase()
      .localeCompare((valueB || "").toLowerCase());
  };

  const selectionChanged = (grid: any) => {
    setSelectedRows(grid.api.getSelectedRows());
  };

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        field: "material_id",
        colId: "material_id",
        tooltipField: "material_id",
        headerName: materialHeaderMap?.material_id?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.material_id?.toUpperCase() || "",
        tooltipComponentParams: { color: "#ececec" },
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: () => {
          return true;
        },
        width: 90,
        pinned: "left",
        lockPosition: "left",
        editable: false,
        // sort: "asc",
        lockVisible: true,
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <Link
              className="gridIdLink"
              to={{
                pathname: `/project/${projectId}/materials/${params.data.id}`
              }}
            >
              {params.value}
            </Link>
          );
        }
      },
      {
        field: "name",
        colId: "name",
        headerName: materialHeaderMap?.name?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.name?.toUpperCase() || "",
        editable: genInfoAndParticipantsCellsEditable,
        cellClass: (params: any) => {
          return genInfoAndParticipantsCellsEditable(params)
            ? "cell-editable"
            : "";
        },
        cellRenderer: materialCellRenderer,
        cellRendererParams: { projectId },
        tooltipComponent: (params: any) => {
          if (params.location === "header")
            return (
              <div className="matnameheadertooltip">
                {materialHeaderMap?.name?.toUpperCase() || ""}
              </div>
            );

          const { data } = params;
          return (
            <div className="matnameheadertooltip">
              <div>{data?.name || ""}</div>
              <div>{data?.description || ""}</div>
            </div>
          );
        },
        // width: 160,
        pinned: "left",
        lockPosition: "left",
        filter: false,
        comparator(valueA: any, valueB: any, nodeA: any, nodeB: any) {
          const compareA = nodeA.data.name ?? nodeA.data.description;
          const compareB = nodeB.data.name ?? nodeB.data.description;
          return compareA.toLowerCase().localeCompare(compareB.toLowerCase());
        },
        getQuickFilterText: (params: any) => {
          try {
            return `${params.data.name}\n${params.data.description}`;
          } catch (error) {
            return "";
          }
        },
        tooltipValueGetter: (params: any) => {
          if (!params.data?.description) return params.data.name || "";
          try {
            const tooltip = `${params.data.name} - ${params.data.description}`;
            return tooltip;
          } catch (error) {
            return "";
          }
        },
        lockVisible: true
      },
      {
        colId: "description",
        field: "description",
        headerName: "Description",
        headerTooltip: "Description",
        editable: false,
        hide: true,
        suppressColumnsToolPanel: true,
        filter: false,
        menuTabs: [],
        pinned: "left",
        lockPosition: "left"
      },
      {
        field: "status",
        colId: "status",
        headerName: materialHeaderMap?.status?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.status?.toUpperCase() || "",
        cellRenderer: logStatusCellRenderer,
        menuTabs: ["filterMenuTab"],
        filter: true,
        lockVisible: true,
        minWidth: 200
      },
      {
        colId: "next_planned_deadline",
        field: "next_planned_deadline",
        sort: "asc",
        headerName:
          materialHeaderMap?.next_planned_deadline?.toUpperCase() || "",
        headerTooltip:
          materialHeaderMap?.next_planned_deadline?.toUpperCase() || "",
        width: 200,
        minWidth: 30,
        maxWidth: 220,
        editable: false,
        cellEditorPopup: false,
        suppressMenu: false,
        menuTabs: ["filterMenuTab"],
        cellRenderer: nextDeadlineCellRenderer,
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: materialHeaderMap?.next_planned_deadline?.toUpperCase() || "",
          columnData: {
            field: "next_planned_deadline",
            header:
              materialHeaderMap?.next_planned_deadline?.toUpperCase() || ""
          },
          setCustomDateFilter
        },
        getQuickFilterText: () => "",
        tooltipValueGetter: (params: any) => {
          return DateUtils.format(params.value);
        }
      },
      {
        colId: "due_date",
        field: "due_date",
        headerName: materialHeaderMap?.due_date?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.due_date?.toUpperCase() || "",
        headerComponentParams: materialDueDateHeaderComponent,
        width: 200,
        minWidth: 30,
        maxWidth: 220,
        editable: false,
        cellEditorPopup: false,
        cellRenderer: dateCellRenderer,
        comparator: DateFilter.comparator,
        menuTabs: ["filterMenuTab"],
        filter: CustomDateFilters,
        filterParams: {
          title: materialHeaderMap?.due_date?.toUpperCase() || "",
          columnData: {
            field: "due_date",
            header: materialHeaderMap?.due_date?.toUpperCase() || ""
          },
          setCustomDateFilter
        },
        getQuickFilterText: () => "",
        tooltipValueGetter: (params: any) => {
          return DateUtils.format(params.value);
        }
      },
      {
        colId: "risk_level",
        field: "risk_level",
        headerName: materialHeaderMap?.risk_level?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.risk_level?.toUpperCase() || "",
        width: 100,
        minWidth: 30,
        maxWidth: 120,
        menuTabs: ["filterMenuTab"],
        editable: false,
        cellEditorPopup: false,
        tooltipField: "risk_level",
        filter: true
      },
      {
        colId: "float",
        field: "float",
        headerName: materialHeaderMap?.float?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.float?.toUpperCase() || "",
        width: 130,
        minWidth: 30,
        maxWidth: 170,
        menuTabs: ["filterMenuTab"],
        editable: canChangeMaterialDBFloat,
        cellEditorPopup: false,
        cellEditor: numberCellEditor,
        cellRenderer: editableValueRenderer,
        tooltipField: "float",
        filter: true,
        cellClass: (params: any) => {
          return canChangeMaterialDBFloat(params) ? "cell-editable" : "";
        },
        getQuickFilterText: () => ""
      },
      {
        field: "custom_spec_section",
        colId: "custom_spec_section",
        headerName: materialHeaderMap?.spec_section_no?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.spec_section_no?.toUpperCase() || "",
        // pinned: "left",
        // lockPosition: "left",
        width: 250,
        minWidth: 100,
        maxWidth: 500,
        filter: "agSetColumnFilter",
        suppressMenu: false,
        menuTabs: ["filterMenuTab"],
        sortable: true,
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              return params.value.split("-")[0].trim();
            } catch {
              return params.value;
            }
          }
        },
        editable: genInfoAndParticipantsCellsEditable,
        cellClass: (params: any) => {
          return genInfoAndParticipantsCellsEditable(params)
            ? "cell-editable"
            : "";
        },
        cellEditor: SpecSectionCellEditor,
        cellEditorParams: {
          specNoField: "spec_section_no",
          specNameField: "spec_section_name"
        },
        cellRenderer: specSectionRenderer,
        cellRendererParams: {
          specNoField: "spec_section_no",
          specNameField: "spec_section_name"
        },
        valueGetter: (params: any) => {
          try {
            let specSectionStr = params.data.spec_section_no || "";
            if (params.data.spec_section_name) {
              specSectionStr += ` - ${params.data.spec_section_name}`;
            }
            return specSectionStr;
          } catch (ex) {
            return "";
          }
        },
        comparator: customComparator,
        tooltipValueGetter: (params) => {
          try {
            let specSectionStr = params.data.spec_section_no || "";
            if (params.data.spec_section_name) {
              specSectionStr += ` - ${params.data.spec_section_name}`;
            }
            return specSectionStr;
          } catch (ex) {
            return "";
          }
        }
      },
      {
        field: "material_tag",
        colId: "material_tag",
        tooltipField: "material_tag",
        headerName: materialHeaderMap?.tag_name?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.tag_name?.toUpperCase() || "",
        menuTabs: ["filterMenuTab"],
        filter: true,
        editable: genInfoAndParticipantsCellsEditable,
        cellRenderer: editableValueRenderer,
        cellClass: (params: any) => {
          return genInfoAndParticipantsCellsEditable(params)
            ? "cell-editable"
            : "";
        }
      },
      {
        field: "date_block_template_name",
        colId: "date_block_template_name",
        tooltipField: "date_block_template_name",
        headerName:
          materialHeaderMap?.date_block_template_name?.toUpperCase() || "",
        headerTooltip:
          materialHeaderMap?.date_block_template_name?.toUpperCase() || "",
        menuTabs: ["filterMenuTab"],
        filter: true,
        editable: canChangeMaterialDBTemplate,
        cellEditor: MaterialTemplateSelectCellEditor,
        cellEditorParams: {
          showSearch: true,
          MDBTemplatesContextKey: "MDBTemplates"
        },
        cellRenderer: editableValueRenderer,
        cellClass: (params: any) => {
          return canChangeMaterialDBTemplate(params) ? "cell-editable" : "";
        }
      },
      {
        field: "quantity_unit",
        colId: "quantity_unit",
        headerName: materialHeaderMap?.quantity?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.quantity?.toUpperCase() || "",
        width: 260,
        editable: materialCharacteristicsCellsEditable,
        cellRenderer: editableValueRenderer,
        valueGetter: (param: any) =>
          `${param.data.quantity ?? ""} ${param.data.quantity_unit || ""}`,
        tooltipValueGetter: ({ value }) => value,
        cellClass: (params: any) => {
          return materialCharacteristicsCellsEditable(params)
            ? "cell-editable"
            : "";
        },
        cellEditor: measurementSelectEditor,
        cellEditorParams: {
          type: "regular-select",
          dataMapVar: "material_quantity_unit",
          setKeyId: "quantity_unit_id",
          unitValueProp: "quantity",
          unit: "quantity_unit"
        }
      },
      {
        field: "trade_partner",
        colId: "trade_partner",
        headerName: materialHeaderMap?.trade_partner?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.trade_partner?.toUpperCase() || "",
        tooltipField: "trade_partner_name",
        editable: genInfoAndParticipantsCellsEditable,
        cellClass: (params: any) => {
          return genInfoAndParticipantsCellsEditable(params)
            ? "cell-editable"
            : "";
        },
        filter: true,
        menuTabs: ["filterMenuTab"],
        cellEditor: ResponsibleContractorCellEditor,
        cellEditorParams: {
          type: "regular-select",
          source: "material",
          showSearch: true,
          projectId
        },
        cellRenderer: (params: any) => {
          const data = responsibleContractorCellRendererWithCustomEdit(params);
          return data.cellMarkup;
        },
        valueGetter: (params: any) => {
          const data = responsibleContractorCellRenderer(params);
          return data.orgName;
        },
        tooltipValueGetter: (params: any) => {
          const data = responsibleContractorCellRenderer(params);
          return data.orgName;
        },
        lockVisible: true
      },
      {
        field: "assignee",
        colId: "assignee",
        headerName: materialHeaderMap?.assignee?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.assignee?.toUpperCase() || "",

        filter: true,
        menuTabs: ["filterMenuTab"],
        editable: genInfoAndParticipantsCellsEditable,
        cellClass: (params: any) => {
          return genInfoAndParticipantsCellsEditable(params)
            ? "cell-editable"
            : "";
        },
        cellEditor: MaterialAssigneeSelectCellEditor,
        cellRenderer: (params: any) => {
          const data = userOrgCellRendererWithCustomEdit(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "assignee",
            firstName: "assignee_first_name",
            lastName: "assignee_last_name",
            org: "trade_partner_name",
            unregistered_user: "assignee_unregistered",
            unregistered_user_org: "trade_partner_name",
            statusKey: "assignee_status"
          }
        },
        valueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        lockVisible: true
      },
      {
        field: "gc_representative",
        colId: "gc_representative",
        headerName: materialHeaderMap?.gc_representative?.toUpperCase() || "",
        headerTooltip:
          materialHeaderMap?.gc_representative?.toUpperCase() || "",
        filter: true,
        menuTabs: ["filterMenuTab"],
        editable: genInfoAndParticipantsCellsEditable,
        cellClass: (params: any) => {
          return genInfoAndParticipantsCellsEditable(params)
            ? "cell-editable"
            : "";
        },
        cellEditor: MaterialGCRepresentativeSelectCellEditor,
        cellRenderer: (params: any) => {
          const data = gcRepresentativeCellRendererWithCustomEdit(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "gc_representative",
            firstName: "gc_representative_first_name",
            lastName: "gc_representative_last_name",
            statusKey: "gc_representative_status"
          }
        },
        valueGetter: (params: any) => {
          const data = gcRepresentativeCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params: any) => params.value
      },
      {
        field: "manufacturer",
        colId: "manufacturer",
        headerName: materialHeaderMap?.manufacturer?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.manufacturer?.toUpperCase() || "",
        tooltipField: "manufacturer",
        editable: genInfoAndParticipantsCellsEditable,
        cellClass: (params: any) => {
          return genInfoAndParticipantsCellsEditable(params)
            ? "cell-editable"
            : "";
        },
        cellRenderer: editableValueRenderer,
        filter: false,
        comparator: customComparator
      },
      {
        colId: "linked_tasks",
        field: "tasks",
        headerName: materialHeaderMap?.linked_activities?.toUpperCase() || "",
        headerTooltip:
          materialHeaderMap?.linked_activities?.toUpperCase() || "",
        filter: false,
        menuTabs: [],
        width: 300,
        maxWidth: 500,
        cellRenderer: TaskLinksCellRenderer,
        editable: false
      },
      {
        colId: "linked_submittals",
        field: "submittals",
        headerName: materialHeaderMap?.linked_submittals?.toUpperCase() || "",
        headerTooltip:
          materialHeaderMap?.linked_submittals?.toUpperCase() || "",
        filter: false,
        menuTabs: [],
        width: 300,
        maxWidth: 500,
        cellRenderer: SubmittalLinksCellRenderer,
        editable: false
      },
      {
        field: "material_size_unit",
        colId: "material_size_unit",
        headerName: materialHeaderMap?.material_size?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.material_size?.toUpperCase() || "",
        width: 260,
        editable: materialCharacteristicsCellsEditable,
        cellRenderer: editableValueRenderer,
        valueGetter: (param: any) =>
          `${param.data.material_size ?? ""} ${
            param.data.material_size_unit || ""
          }`,
        tooltipValueGetter: ({ value }) => value,
        cellClass: (params: any) => {
          return materialCharacteristicsCellsEditable(params)
            ? "cell-editable"
            : "";
        },
        cellEditor: measurementSelectEditor,
        cellEditorParams: {
          type: "regular-select",
          dataMapVar: "material_quantity_unit",
          setKeyId: "size_unit_id",
          unitValueProp: "material_size",
          unit: "material_size_unit"
        }
      },
      {
        field: "part_number",
        colId: "part_number",
        headerName: materialHeaderMap?.part_number?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.part_number?.toUpperCase() || "",
        tooltipField: "part_number",
        editable: genInfoAndParticipantsCellsEditable,
        cellClass: (params: any) => {
          return genInfoAndParticipantsCellsEditable(params)
            ? "cell-editable"
            : "";
        },
        cellRenderer: editableValueRenderer,
        filter: false,
        comparator: customComparator
      },
      {
        field: "serial_number",
        colId: "serial_number",
        headerName: materialHeaderMap?.serial_number?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.serial_number?.toUpperCase() || "",
        tooltipField: "serial_number",
        editable: genInfoAndParticipantsCellsEditable,
        cellClass: (params: any) => {
          return genInfoAndParticipantsCellsEditable(params)
            ? "cell-editable"
            : "";
        },
        cellRenderer: editableValueRenderer,
        filter: false,
        comparator: customComparator
      },
      {
        colId: "warranty",
        field: "warranty",
        headerName: materialHeaderMap?.warranty?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.warranty?.toUpperCase() || "",
        valueGetter: (params) =>
          params?.data?.warranty
            ? `${params?.data?.warranty}  ${
                params?.data?.warranty === 1 ? "year" : "years"
              }`
            : "",
        width: 160,
        editable: genInfoAndParticipantsCellsEditable,
        cellEditorPopup: false,
        cellEditor: WarrantyCellEditor,
        cellClass: (params: any) => {
          return genInfoAndParticipantsCellsEditable(params)
            ? "cell-editable"
            : "";
        },
        tooltipValueGetter: ({ value }) => value,
        getQuickFilterText: () => ""
      },
      {
        colId: "next_action",
        field: "next_action",
        headerName: materialHeaderMap?.next_action?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.next_action?.toUpperCase() || "",
        width: 100,
        minWidth: 30,
        maxWidth: 120,
        editable: false,
        cellEditorPopup: false,
        tooltipField: "next_action",
        suppressColumnsToolPanel: true,
        hide: true,
        getQuickFilterText: () => ""
      },
      {
        colId: "created_at",
        field: "created_at",
        headerName: materialHeaderMap?.created_at?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.created_at?.toUpperCase() || "",
        width: 200,
        minWidth: 30,
        maxWidth: 220,
        editable: false,
        cellEditorPopup: false,
        comparator: DateFilter.comparator,
        filter: CustomDateFilters,
        filterParams: {
          title: materialHeaderMap?.created_at?.toUpperCase() || "",
          columnData: {
            field: "created_at",
            header: materialHeaderMap?.created_at?.toUpperCase() || ""
          },
          setCustomDateFilter
        },
        getQuickFilterText: () => "",
        tooltipValueGetter: (params: any) => {
          return DateUtils.format(params.value);
        },
        tooltipField: "created_at",
        suppressColumnsToolPanel: true,
        hide: true
      },
      {
        colId: "quantity_unit_id",
        field: "quantity_unit_id",
        headerName: "quantity_unit_id",
        headerTooltip: "quantity_unit_id",
        editable: false,
        hide: true,
        suppressColumnsToolPanel: true,
        filter: false,
        menuTabs: [],
        getQuickFilterText: () => ""
      },
      {
        colId: "size_unit_id",
        field: "size_unit_id",
        headerName: "size_unit_id",
        headerTooltip: "size_unit_id",
        editable: false,
        hide: true,
        suppressColumnsToolPanel: true,
        filter: false,
        getQuickFilterText: () => "",
        menuTabs: []
      },
      {
        colId: "action",
        headerName: "ACTION",
        headerTooltip: "ACTION",
        pinned: "right",
        width: 70,
        editable: false,
        suppressColumnsToolPanel: true,
        onCellClicked(params: any) {
          history.push(
            `/project/${projectId}/schedule?tab=4&materialId=${params.data.id}`
          );
        },
        cellRenderer: () => <LinkOutlined />
      },
      ...milestonesColumns.groupColDefMilestone,
      ...milestonesColumns.mileStoneColumns
    ];
  }, [
    materialHeaderMap,
    projectId,
    canChangeMaterialDBFloat,
    canChangeMaterialDBTemplate,
    milestonesColumns,
    history
  ]);

  const editMaterialNewDBOffset = async (
    event: any,
    variables: { id: string; set: any }
  ) => {
    const updateVars = {
      variables: {
        where: { material_id: { _eq: variables.id } },
        set: variables.set
      }
    };
    const res = await updateNewDateBlock(updateVars);
    if (res.data) {
      message.success("Updated successfully");
    }
    if (res.errors) {
      event.node.setDataValue(event.colDef.colId, event.oldValue);
      message.error(res.errors[0].message);
    }
  };

  const updateMaterialCell = async (
    event: any,
    variables: {
      set: any;
      pk_columns: { id: any };
    }
  ) => {
    try {
      const updateResponse = await updateMaterial({ variables });
      if (updateResponse.errors) {
        event.node.setDataValue(event.colDef.colId, event.oldValue);
        if (
          updateResponse.errors[0].message &&
          updateResponse.errors[0].message.includes("duplicate")
        ) {
          message.error(ErrorMessages.MaterialExists);
          return;
        }

        message.error(updateResponse.errors[0].message);
        return;
      }
      message.success("Updated successfully");
    } catch (ex: any) {
      console.error(ex);
      message.error(ex.message);
    }
  };

  const onCustomSpecSectionCellEdit = async (event: any) => {
    if (!event.newValue.specIdValue) return;

    const specNo = (event.newValue.specNoValue || "").trim();
    const specName = (event.newValue.specNameValue || "").trim();

    const oldData = { ...event.data };

    event.node.setData({
      ...oldData,
      spec_section_no: specNo,
      spec_section_name: specName
    });

    const updateReqObj = {
      variables: {
        set: {
          spec_section_no: specNo,
          spec_section_name: specName,
          spec_section_id: event.newValue.specIdValue
        },
        pk_columns: { id: event.data.id }
      }
    };

    updateMaterialCell(event, updateReqObj.variables);
  };

  const onMDBTemplateSelect = async (event: any) => {
    if (!event.newValue) return;

    const oldData = { ...event.data };

    const foundTemplate = MDBTemplates?.find((template: any) => {
      return event.newValue === template.id;
    });

    event.node.setData({
      ...oldData,
      date_block_template_id: foundTemplate.id,
      date_block_template_name: foundTemplate.name
    });

    const payload = {
      variables: {
        where: { material_id: { _in: [event.data.id] } },
        set: { workflow_template_id: event.newValue }
      }
    };

    const res = await updateMDB(payload);

    if (res.data) {
      message.success("Updated successfully");
      calculateMaxNumberOfMilestone();
    }
    if (res.errors) {
      event.node.setData({ ...oldData });
      message.error(res.errors[0].message);
    }
  };

  const onMDBFloatChange = async (event: any) => {
    if (!event.newValue) return;

    const oldData = { ...event.data };

    event.node.setData({
      ...oldData,
      float: event.newValue,
      template_value_overidden: true // CIQ-2166 For Updating offset material
    });

    // console.log("event.newValue ", event.newValue);

    const updateVars = {
      variables: {
        where: { material_id: { _in: [event.data.id] } },
        set: { float: event.newValue }
      }
    };
    const res = await updateMDB(updateVars);
    if (res.data) {
      message.success("Updated successfully");
    }
    if (res.errors) {
      event.node.setData({ ...oldData });
      message.error(res.errors[0].message);
    }
  };

  const onCellEditRequest = async (event: any) => {
    if (event.column.colId === "custom_spec_section") {
      onCustomSpecSectionCellEdit(event);
      return;
    }
    if (event.column.colId === "date_block_template_name") {
      onMDBTemplateSelect(event);
      return;
    }
    if (event.column.colId === "float") {
      onMDBFloatChange(event);
      return;
    }

    if (!event.newValue) return;

    const existingRowData = { ...event.data };
    const changedData: any = {};

    const variables: any = {
      pk_columns: { id: event.data.id },
      set: {}
    };

    switch (event.colDef.colId) {
      case "trade_partner":
        {
          const tradePartner = getSubmittalCompanyById(
            event.newValue,
            "materialTradePartners"
          );

          variables.set.assignee = null;
          variables.set.assignee_unregistered = "";
          variables.set.trade_partner = event.newValue;

          changedData.trade_partner = event.newValue;
          changedData.trade_partner_name =
            tradePartner?.subscription_vendor?.name;
          changedData.assignee = null;
          changedData.assignee_unregistered = "";
        }
        break;
      case "assignee":
        {
          const assigneeUser = getSubmittalUserById(
            event.newValue,
            "submitterUsers"
          );

          changedData.trade_partner_name = assigneeUser.company?.name;

          if (assigneeUser.type === "actual") {
            variables.set.assignee = event.newValue;
            variables.set.assignee_unregistered = "";

            changedData.assignee = event.newValue;
            changedData.assignee_first_name = assigneeUser.first_name;
            changedData.assignee_last_name = assigneeUser.last_name;
            changedData.assignee_unregistered = "";
          }
          if (assigneeUser.type === "poc") {
            variables.set.assignee = null;
            variables.set.assignee_unregistered = event.newValue;

            changedData.assignee = null;
            changedData.assignee_unregistered = event.newValue;
          }
        }
        break;
      case "gc_representative":
        {
          const gcRepresentativeUser = getSubmittalUserById(
            event.newValue,
            "gcReviewers"
          );

          variables.set.gc_representative = event.newValue;
          changedData.gc_representative = event.newValue;
          changedData.gc_representative_first_name =
            gcRepresentativeUser.first_name;
          changedData.gc_representative_last_name =
            gcRepresentativeUser.last_name;
        }

        break;
      case "quantity_unit":
        variables.set = {
          [event.newValue.unitValue.prop]: event.newValue.unitValue.value
        };
        changedData[event.newValue.unitValue.prop] =
          event.newValue.unitValue.value;
        if (event.newValue.unitType) {
          variables.set[event.newValue.unitType.prop] =
            event.newValue.unitType.value;
          changedData[event.colDef.colId] = event.newValue.label;
        }
        break;
      case "material_size_unit":
        variables.set = {
          [event.newValue.unitValue.prop]: `${event.newValue.unitValue.value}`
        };

        changedData[event.newValue.unitValue.prop] =
          event.newValue.unitValue.value;
        if (event.newValue.unitType) {
          variables.set[event.newValue.unitType.prop] =
            event.newValue.unitType.value;
          changedData[event.colDef.colId] = event.newValue.label;
        }

        break;
      case "material_tag":
        variables.set = { tag_name: event.newValue };
        changedData[event.colDef.colId] = event.newValue;
        break;
      case "material_PO_fabrication_offset":
      case "fabrication_duration_offset":
      case "lead_time_offset":
      case "on_site_storage_offset":
        event.node.setDataValue(event.colDef.colId, event.newValue);
        {
          const payload = {
            id: event.data.id,
            set: {
              [MaterialOffsetFieldsMappingOldToNew[event.colDef.field]]:
                event.newValue
            }
          };
          editMaterialNewDBOffset(event, payload);
        }
        return;
        break;

      default:
        variables.set = { [event.colDef.field]: event.newValue };
        changedData[event.colDef.colId] = event.newValue;
        break;
    }

    event.node.setData({ ...existingRowData, ...changedData });
    updateMaterialCell(event, variables);
  };

  const resetFilters = () => {
    setGridSearchtext("");
    gridRef?.current?.api?.setFilterModel(null);
    clearFilterRef.current = true;
    setTimeout(() => {
      // To destroy all filters applied on chip
      clearFilterRef.current = false;
      onSaveColumnState();
    }, 2000);
  };

  const onFiltersApplied = (event: any) => {
    const filtersApplied = event.api.getFilterModel();
    if (filtersApplied) {
      const items: FilterItem[] = new Array<FilterItem>();
      Object.keys(filtersApplied).forEach((key: any) => {
        if (
          filtersApplied[key]?.values &&
          filtersApplied[key].values.length > 0
        ) {
          const field = columnDefs.filter((x) => x.field === key)[0];
          const { values, range } = filtersApplied[key];
          if (field?.cellEditorParams?.type === "currency") {
            values.forEach((value: any, index: number) => {
              values[index] = parseFloat(value).toFixed(2);
            });
          }
          items.push({
            field: key,
            header: field ? field.headerName : key,
            value: values,
            range
          });
        }
      });

      setFilters(items);

      if (clearFilterRef.current === false && isFirstDataRendered === true) {
        if (!materialListFilter) {
          onSaveColumnState();
        }
      }
    }

    calculateMaxNumberOfMilestone();
    updateStats();
  };

  const onNewClick = () => {
    setShowNewDrawer(true);
  };

  const onExtractionClick = () => {
    setShowExtractionPanel(true);
  };

  const onImportClick = () => {
    if (!projectMaterials) return;
    existingMaterialsCountRef.current = gridData.projectMaterials.length;
    setShowImportLogDrawer(true);
  };

  const onApplyClick = () => {
    setShowNewDrawer(true);
  };

  const resetGridFilters = useCallback(() => {
    try {
      const currentFilters: any = gridRef.current?.api?.getFilterModel();
      const currentFilterCols = Object.keys(currentFilters);
      currentFilterCols.forEach((col: string) => {
        gridRef.current!.api.destroyFilter(col);
      });

      setTimeout(() => {
        setMaterialListFilter(null);
      });
    } catch (ex) {
      //
    }
  }, [setMaterialListFilter]);

  const bulkEditMaterialDetails = (panel: "details" | "duration") => {
    if (panel === "details") {
      setShowBulkEditMaterialDetails(true);
    }
    if (panel === "duration") {
      setShowBulkEditMaterialDurations(true);
    }
  };

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => params.data.id;
  }, []);

  const getFormattedValues = (params: any) => {
    const { value, column, node } = params;

    switch (true) {
      case column.colId === "custom_spec_section":
        try {
          const { data } = node;
          let specSectionStr = data.spec_section_no || "";
          if (data.spec_section_name) {
            specSectionStr += ` - ${data.spec_section_name}`;
          }
          return specSectionStr;
        } catch (ex) {
          return "";
        }
      case column.colId === "name":
        try {
          return node.data.name || "";
        } catch (ex) {
          return "";
        }
      case column.colDef.cellRenderer === dateCellRenderer:
        try {
          return value ? DateUtils.format(value) : "";
        } catch (ex) {
          return "";
        }
      case column.colId === "quantity_unit":
        try {
          const { data } = node;
          return `${data.quantity ?? ""} ${data.quantity_unit || ""}`;
        } catch (ex) {
          return "";
        }
      case column.colId === "material_size_unit":
        try {
          const { data } = node;
          return `${data.material_size ?? ""} ${data.material_size_unit || ""}`;
        } catch (ex) {
          return "";
        }
      case column.colId === "assignee":
        try {
          const { data } = node;
          const userData = userOrgCellRenderer({
            data,
            userInput: column.userProvidedColDef.cellRendererParams.userInput
          });
          return `${userData.fullName} (${userData.userOrg})`;
        } catch (ex) {
          return "";
        }
      case column.colId === "linked_tasks":
        try {
          const tasks = value || [];

          const formattedItems = tasks.map((item: any) => {
            const name = item.activity_id
              ? `${item.activity_id} - ${item.task_name}`
              : item.task_name;
            return name;
          });

          return formattedItems.join("\n");
        } catch (ex) {
          return "";
        }
      case column.colId === "linked_submittals":
        try {
          const submittals = value || [];

          const formattedItems = submittals.map((item: any) => {
            return item.name;
          });

          return formattedItems.join("\n");
        } catch (ex) {
          return "";
        }
      case column.colId === "next_planned_deadline":
        try {
          if (!params.value) return "";
          return DateUtils.format(params.value);
        } catch (ex) {
          return "";
        }
        break;
      default:
        return value ?? "";
    }
  };

  const getParams: () => CsvExportParams = () => ({
    columnKeys: gridRef.current?.columnApi
      ?.getAllGridColumns()
      ?.filter((col) => {
        const colId = col.getColId();
        if (colId === "description" || colId === "next_action") {
          return true;
        }
        if (
          milestonesColumns.groupColDefMilestone.some((x) => x.colId === colId)
        ) {
          return false;
        }
        return col.isVisible() && !hiddenColumns?.includes(colId);
      })
      .map((col) => col.getColId()),
    processCellCallback(params: any): string {
      return getFormattedValues(params);
    },
    fileName: `Material log_${projectDetails?.name}.csv`
  });

  const exportLogAsCSV = () => {
    gridRef.current?.api?.exportDataAsCsv(getParams());
  };

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true
          }
        }
      ]
    };
  }, []);

  const showMaterialList = useMemo(() => {
    if (materialHeaderMap && savedColumns?.materials?.columnList) {
      return true;
    }
    return false;
  }, [materialHeaderMap, savedColumns?.materials?.columnList]);

  const openNotification = (
    placement: NotificationPlacement,
    id: string,
    title: string
  ) => {
    api.open({
      message: (
        <div className="notifications space-x-1">
          <span className="notification-highlight-title ">{title}</span>
          <span className="notification-title">successfully created.</span>
        </div>
      ),
      description: (
        <div className="notifications space-x-1">
          <span className="notification-subTitle">Click</span>
          <a
            href={`/project/${projectId}/materials/${id}`}
            className="underline notification-highlight-subTitle "
          >
            here
          </a>
          <span className="notification-subTitle">to view details.</span>
        </div>
      ),
      placement
    });
  };

  const onEditCell = useCallback(
    (params: any) => {
      gridRef.current!.api.setFocusedCell(params.rowIndex, params.colKey);
      gridRef.current!.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.colKey
      });
    },
    [gridRef]
  );

  return (
    <div className="material-list-page h-screen flex flex-col">
      {contextHolder}
      {showMaterialList ? (
        <>
          <div>
            <MaterialListFilterBar
              gridRef={gridRef}
              onNewClick={onNewClick}
              onExtractionClick={onExtractionClick}
              onImportClick={onImportClick}
              onApplyClick={onApplyClick}
              stats={stats}
              onSearch={setGridSearchtext}
              setCustomDateFilter={setCustomDateFilter}
              customDateFilter={customDateFilter}
              items={filters}
              setItems={setFilters}
              showFiterChips={!materialListFilter}
              onAllMaterialsClick={resetGridFilters}
              selectedRows={selectedRows}
              onMaterialEditClick={bulkEditMaterialDetails}
              onExportCSV={exportLogAsCSV}
              displayRowCount={displayRowCount}
              resetFilters={resetFilters}
              setFilterStateFromData={setFilterStateFromData}
              getColumnStateFromGrid={getColumnStateFromGrid}
              afterApplyFilterCallBack={() => {
                calculateMaxNumberOfMilestone();
              }}
              isGridDataRendered={!projectMaterials?.material_list_func?.length}
              onMaterialDeleted={() => {}}
            />
          </div>

          <div className="material-listing-grid grow ag-theme-alpine h-full px-2 relative">
            <AgGridReact<TMaterialItem>
              ref={gridRef}
              rowData={gridData.projectMaterials}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              suppressRowClickSelection
              suppressAggFuncInHeader
              headerHeight={36}
              readOnlyEdit
              onCellEditRequest={onCellEditRequest}
              loadingOverlayComponent={GridLoadingIndicator}
              suppressContextMenu
              suppressDragLeaveHidesColumns
              maintainColumnOrder
              enableCellTextSelection
              singleClickEdit
              stopEditingWhenCellsLoseFocus
              rowHeight={55}
              onFilterChanged={onFiltersApplied}
              onSortChanged={onFiltersApplied}
              onSelectionChanged={selectionChanged}
              context={{
                projectMaterials: gridData.projectMaterials,
                material_quantity_unit: allUnits?.material_quantity_unit,
                canInlineEditMaterialGeneralInfoAndParticipants,
                canInlineEditMaterialCharacteristics,
                canInlineEditMaterialDBOffset,
                tokenContents,
                projectDetails,
                projectParticipants,
                isCurrentUserGC,
                materialHeaderMap,
                MDBTemplatesDropdownOptions,
                MDBTemplates,
                onEditCell,
                gqlClientForProject
              }}
              onGridReady={() => {
                setGridReady(true);
              }}
              sideBar={sideBar}
              overlayNoRowsTemplate="Import a log or click New material to create a log entry."
              onFirstDataRendered={onFirstDataRendered}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              getRowId={getRowId}
              onColumnVisible={(e) => {
                const hiddenCols =
                  gridRef.current?.columnApi
                    .getColumns()
                    ?.filter((column: any) => !column.visible)
                    .map((col: any) => col.colId) || [];

                setHiddenColumns([...hiddenCols, "action"]);

                if (e.columnApi && e.source === "toolPanelUi") {
                  onSaveColumnState();
                  if (e.column) {
                    milestonesColumns.onColumnVisible(e, maxNumberOfMilestone);
                  } else {
                    milestonesColumns.updateAllMileStoneInitialVisibility(
                      e.columnApi,
                      maxNumberOfMilestone
                    );
                  }
                }
              }}
              onDragStopped={(e) => {
                const classNames = [
                  "ag-header-cell-resize",
                  "ag-tool-panel-horizontal-resize"
                ];
                if (e.columnApi && !classNames.includes(e.target.className)) {
                  onSaveColumnState();
                }
              }}
              suppressClickEdit
            />

            {showExtractionPanel && (
              <div className="absolute top-0 left-0 right-0 w-full h-full">
                <MaterialExtraction
                  onClose={() => {
                    setShowExtractionPanel(false);
                  }}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="h-[80vh] flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}

      {/* <Drawer
        title="Create Material"
        placement="right"
        width={400}
        onClose={onDrawerClose}
        open={showNewDrawer}
      >
        
      </Drawer> */}
      {showNewDrawer && (
        <MaterialCreateComponent
          showCreateLogDrawer={showNewDrawer}
          setDrawerOpen={setShowNewDrawer}
          onCreateClick={(id: string, title: string) => {
            openNotification("top", id, title);
          }}
          MDBTemplates={MDBTemplates}
        />
      )}
      {showImportLogDrawer && (
        <ImportMaterialLogs
          materialHeaderMap={materialHeaderMap}
          existingMaterialsCount={existingMaterialsCountRef.current}
          showImportLogDrawer={showImportLogDrawer}
          onCancel={() => {
            setShowImportLogDrawer(false);
          }}
        />
      )}
      {showBulkEditMaterialDetails && (
        <BulkEditMaterialDetails
          onCancelClick={() => {
            setShowBulkEditMaterialDetails(false);
          }}
          onUpdateDone={() => {
            setShowBulkEditMaterialDetails(false);
            gridRef.current!.api.deselectAll();
          }}
          selectedRows={selectedRows}
          gqlClient={gqlClientForProject}
          setShowEdit={setShowBulkEditMaterialDetails}
          showEdit={showBulkEditMaterialDetails}
          projectId={projectId}
          MDBTemplates={MDBTemplates}
        />
      )}
      {showBulkEditMaterialDurations && (
        <BulkEditMaterialDurations
          onUpdateDone={() => {
            setShowBulkEditMaterialDurations(false);
            gridRef.current!.api.deselectAll();
          }}
          selectedRows={selectedRows}
          showEdit={showBulkEditMaterialDurations}
          MDBTemplates={MDBTemplates}
          materials={gridData.projectMaterials}
        />
      )}
    </div>
  );
}

export default Materials;
