export const DESIGN_PACKAGE_LIST_VIEW = `DesignPackageListQuery {
   design_package_list_view {
    id
    auto_inc_value
    number
    title
    description
    workflow_status
    milestone_state
    milestones
    offsets
    design_firm_id
    design_firm_name
    responsible_designer_id
    responsible_designer_first_name
    responsible_designer_last_name
    responsible_designer_status_id
    gc_representative_id
    gc_representative_first_name
    gc_representative_last_name
  }
}`;
