import { Form } from "antd";
import GCReviewersDropdown from "components/gc-reveiewer-dropdown";
import { UserParticipationType } from "constants/index";
import { useEffect } from "react";

function OwnersInfo(props: { actions?: any; featureInstanceData?: any }) {
  const { actions, featureInstanceData } = props;
  const [form] = Form.useForm();

  const updateOwners = async (
    selectedUserId: string,
    userParticipationType: UserParticipationType
  ) => {
    await actions.updateFeatureUserParticipant({
      user_id: selectedUserId,
      user_participation_type_id: userParticipationType
    });
  };

  const onPreConstructionLeadChange = async (selectedUserId: string) => {
    updateOwners(selectedUserId, UserParticipationType.PRECONSTRUCTION_LEAD);
  };

  const onOperationsLeadChange = async (selectedUserId: string) => {
    updateOwners(selectedUserId, UserParticipationType.OPERATIONS_LEAD);
  };

  useEffect(() => {
    if (!featureInstanceData) return;
    const preConLeadInfo = featureInstanceData?.feature_user_paticipants?.find(
      (user: any) =>
        user?.user_participation_type_id ===
        UserParticipationType.PRECONSTRUCTION_LEAD
    );

    const operationLead = featureInstanceData?.feature_user_paticipants?.find(
      (user: any) =>
        user?.user_participation_type_id ===
        UserParticipationType.OPERATIONS_LEAD
    );

    form.setFieldsValue({
      pre_con_lead: preConLeadInfo?.user?.id,
      operation_lead: operationLead?.user?.id
    });
  }, [featureInstanceData, form]);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item label="PRECONSTRUCTION LEAD" name="pre_con_lead">
        <GCReviewersDropdown onChange={onPreConstructionLeadChange} />
      </Form.Item>
      <Form.Item label="OPERATIONS LEAD" name="operation_lead">
        <GCReviewersDropdown onChange={onOperationsLeadChange} />
      </Form.Item>
    </Form>
  );
}

export default OwnersInfo;
