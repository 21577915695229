import { AgGridReact } from "ag-grid-react";
import { TCiqLogHeader } from "components/ciq-grid-view-wrapper/model";
import { useMemo } from "react";
import { FeatureTypes } from "entity-app/constants";
import { entityLogFilterTitles } from "../../constants";

export const calculateLogPageStats = (props: {
  gridRef: React.RefObject<AgGridReact<any>>;
  ListFilter?: any;
  featureTypeId: FeatureTypes;
}) => {
  const { gridRef, ListFilter, featureTypeId } = props;
  if (!gridRef.current?.api) return undefined;
  const displayedRowCount = gridRef.current?.api?.getDisplayedRowCount();
  if (displayedRowCount === undefined) return undefined;

  const data = {
    displayCount: displayedRowCount,
    filterName: undefined
  };

  if (featureTypeId && ListFilter && ListFilter.key) {
    const titles = entityLogFilterTitles(featureTypeId);
    const titleText = titles[ListFilter.key];
    data.filterName = titleText;
  }

  return data;
};

function CiqLogPageHeader({ entityName, items, titleParam }: TCiqLogHeader) {
  const stats = useMemo(() => {
    if (!titleParam) return entityName;

    const { filterStats, totalCount, title } = titleParam;

    if (filterStats && totalCount) {
      if (filterStats.filterName) {
        return (
          <>
            <span>{`${filterStats.displayCount} ${title}`} </span>
            <span className="text-xs font-thin">
              ({filterStats.filterName})
            </span>
          </>
        );
      }
      if (filterStats.displayCount === totalCount)
        return `${filterStats.displayCount} ${title}`;
      return `${filterStats.displayCount} of ${totalCount} ${title}`;
    }
    if (totalCount) return `${totalCount} ${title}`;
    return "";
  }, [entityName, titleParam]);

  return (
    <div className="p-2 flex justify-between items-center">
      <div className="font-semibold text-sm uppercase">{stats}</div>
      <div className="flex space-x-2 items-center">{items}</div>
    </div>
  );
}
export default CiqLogPageHeader;
