import { gql, useMutation } from "@apollo/client";
import { Button, Form, Input, message, Modal } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import ResponsibleDesignFirmsDropdown from "components/responsible-design-firms";
import { ErrorMessages } from "constants/index";
import { ProjectContext } from "context/ProjectProvider";
import { MUTATION_CREATE_FEATURE_INSTANCE } from "entity-app/graphQL/ciq-feature-mutations";
import { useContext, useState } from "react";
import ResponsibleDesignerComponent from "components/responsible-designers";
import { validatorForFeatureTitle } from "utils/utils";
import GCReviewersDropdown from "components/gc-reveiewer-dropdown";

function CreateDesignPackageComponent(props: {
  setDrawerOpen: Function;
  showDrawerOpen: boolean;
  modelTitle: string;
  onDesignPackageCreated: any;
}) {
  const [form] = Form.useForm();
  const { setDrawerOpen, showDrawerOpen, modelTitle, onDesignPackageCreated } =
    props;
  const [isLoading, setLoading] = useState(false);
  const { gqlClientForProject, tokenRetrievalState } =
    useContext(ProjectContext);
  const selectedDesignFirmId = Form.useWatch("design_firm", form);
  const token = tokenRetrievalState?.token;

  const [createDesignPackage] = useMutation<any>(
    gql(MUTATION_CREATE_FEATURE_INSTANCE),

    {
      client: gqlClientForProject
    }
  );

  const onFinish = async (values: any) => {
    const variables = {
      feature_type: "DESIGN_PACKAGE",
      feature_instances: [
        {
          number: values?.number,
          title: values?.title,
          description: values?.description,
          design_firm: values?.design_firm,
          responsible_designer: values?.responsible_designer,
          gc_representative: values?.gc_representative
        }
      ]
    };

    setLoading(true);

    const creatResponse: any = await createDesignPackage({
      variables
    });

    setLoading(false);

    if (creatResponse?.data?.insert_feature_instances_multi?.ids?.length) {
      onDesignPackageCreated(
        values?.title,
        creatResponse?.data?.insert_feature_instances_multi?.ids[0]
      );
    } else {
      message.error("Unable to create design package.");
    }
  };

  return (
    <Modal
      title={modelTitle}
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ minHeight: "calc(100vh - 96px)" }}
      footer={null}
      open={showDrawerOpen}
      onCancel={() => {
        setDrawerOpen(false);
      }}
      destroyOnClose
    >
      <div>
        <Form
          preserve={false}
          form={form}
          scrollToFirstError
          layout="vertical"
          onFinish={onFinish}
        >
          <FormItem
            name="number"
            label="Number"
            rules={[
              {
                required: true,
                validateTrigger: "onSubmit",
                message: ErrorMessages.designPackgeNumber
              }
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            name="title"
            label="Design Package Title"
            rules={[
              {
                required: true,
                validateTrigger: "onSubmit",
                message: ErrorMessages.designPackgeTitle
              },
              {
                validator: (_, value) => {
                  return validatorForFeatureTitle(
                    value,
                    "DESIGN_PACKAGE",
                    token,
                    ErrorMessages.DesignPackageTitleMsg
                  );
                }
              }
            ]}
          >
            <Input />
          </FormItem>

          <FormItem name="description" label="Description ">
            <TextArea />
          </FormItem>
          <Form.Item label="Responsible Design Firm" name="design_firm">
            <ResponsibleDesignFirmsDropdown />
          </Form.Item>
          <Form.Item label="Responsible Designer" name="responsible_designer">
            <ResponsibleDesignerComponent
              selectedDesignFirmId={selectedDesignFirmId}
            />
          </Form.Item>

          <Form.Item label="GC Representative" name="gc_representative">
            <GCReviewersDropdown />
          </Form.Item>

          <div className="flex justify-end space-x-2 pt-4">
            <Button
              disabled={isLoading}
              onClick={() => {
                setDrawerOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              htmlType="submit"
              type="primary"
            >
              Create Design Package
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default CreateDesignPackageComponent;
