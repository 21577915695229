import { GridApi } from "ag-grid-community";
import { ProjectSettingType } from "context/ProjectProvider";
import { downloadStringAsCSVFile } from "utils/utils";
import { activityColDefs } from "../col-defs/activity-col-def";
import { materialColDef } from "../col-defs/material-col-def";
import { submittalColDefs } from "../col-defs/submittal-col-def";

import {
  getActivityValueGetterData,
  getLinkedMaterialGetterData,
  getLinkedSubmittalGetterData
} from "./value-getters";

export const exportActivityReportAsCSV = (
  gridRef: {
    api: GridApi;
  },
  projectDetails: ProjectSettingType | undefined
) => {
  let csvOpString = "";
  const separator = ",";

  const headers = [
    ...activityColDefs.map((x) => x.headerName),
    materialColDef[0].headerName,
    ...materialColDef[0].children.map((x) => x.headerName),
    submittalColDefs[0].headerName,
    ...submittalColDefs[0].children.map((x) => x.headerName)
  ].map((x) => (x ? x.toUpperCase() : ""));

  csvOpString += headers.join(separator);

  gridRef?.api.forEachNodeAfterFilterAndSort((activityRowNode) => {
    if (activityRowNode.data && activityRowNode.detailNode) {
      const rowActivity = getActivityValueGetterData(activityRowNode.data);

      const { childrenMapped } = activityRowNode.detailNode;

      if (childrenMapped) {
        const { gridRefLinkedMaterial, gridRefLinkedSubmittal } =
          childrenMapped;

        if (gridRefLinkedMaterial && gridRefLinkedMaterial.current) {
          (
            gridRefLinkedMaterial.current.api as GridApi
          ).forEachNodeAfterFilterAndSort((mRowNode) => {
            if (mRowNode.data) {
              const row = getLinkedMaterialGetterData({ data: mRowNode.data });

              const rowStrUptoMaterial = rowActivity.concat(row);
              if (mRowNode.detailNode && mRowNode.detailNode.detailGridInfo) {
                const gridInfo = mRowNode.detailNode.detailGridInfo;
                gridInfo.api?.forEachNodeAfterFilterAndSort((sRowNode) => {
                  const row2 = getLinkedSubmittalGetterData({
                    data: sRowNode.data,
                    context: { projectDetails }
                  });

                  const fullStrRow = [...rowStrUptoMaterial, ...row2];
                  csvOpString += "\n";
                  csvOpString += fullStrRow.join(separator);
                });
              } else {
                const fullStrRow = [
                  ...rowStrUptoMaterial,
                  "No Linked Submittal"
                ];
                csvOpString += "\n";
                csvOpString += fullStrRow.join(separator);
              }
            }
          });
        } else {
          const fullStrRow = [...rowActivity, "No Linked Material"];
          csvOpString += "\n";
          csvOpString += fullStrRow.join(separator);
        }

        if (gridRefLinkedSubmittal && gridRefLinkedSubmittal.current) {
          (
            gridRefLinkedSubmittal.current.api as GridApi
          ).forEachNodeAfterFilterAndSort((sRowNode) => {
            const row = getLinkedSubmittalGetterData({
              data: sRowNode.data,
              context: { projectDetails }
            });

            csvOpString += "\n";
            const materialEmptyColumns = ["", "", "", "", "", "", "", ""];
            const c = [...rowActivity, ...materialEmptyColumns, ...row];
            csvOpString += c.join(separator);
          });
        }
      }
    }
  });

  downloadStringAsCSVFile(
    csvOpString,
    `Schedule Lookahead Report_${projectDetails?.name}.csv`
  );
};
