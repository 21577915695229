import { forwardRef } from "react";
import { ScheduleImpactList } from "./ScheduleImpactList";

const RiskCompareSchedule = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  (ref: any) => {
    return <ScheduleImpactList />;
  }
);
export default RiskCompareSchedule;
