import { LinkOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import FeatureLinkingDialog from "components/feature-linking-dialog";
import FeatureItem from "components/feature-linking-dialog/feature-item";
import FeatureLinksDialogHeader from "components/feature-linking-dialog/feature-links-dialog-header";
import FeaturesGrid from "components/feature-linking-dialog/features-grid";
import { ProjectContext } from "context/ProjectProvider";
import { FeatureTypes } from "entity-app/constants";
import useFeatureToFeatureLinks from "entity-app/hooks/feature-to-feature-links";
import ShowDependenciesVisualization from "pages/design-package-details-page/components/view-dependencies";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import FeatureLinkedItem from "components/feature-to-feature-links-view-panel/feature-linked-item";

function buildFeatureLinkChanges(
  selectedFeatures: any[],
  linkedFeatures: any[] | undefined
): {
  insertedLinks: any[];
  deletedLinks: any[];
} {
  const insertedLinks: any[] = [];
  const updatedLinks: any[] = [];
  const deletedLinks: any[] = [];

  const linkedFeaturesMap = new Map(
    linkedFeatures?.map((feature) => [feature.id, feature])
  );

  selectedFeatures.forEach((feature) => {
    const existingFeature = linkedFeaturesMap.get(feature.id);
    if (existingFeature) {
      if (existingFeature.link_type !== feature.link_type) {
        updatedLinks.push(feature);
      }
      linkedFeaturesMap.delete(feature.id);
    } else {
      insertedLinks.push(feature);
    }
  });

  linkedFeaturesMap.forEach((feature) => {
    deletedLinks.push(feature);
  });

  return {
    insertedLinks,
    deletedLinks
  };
}

function generateFeatureLinkPayload(
  featureId: string | undefined,
  changes: ReturnType<typeof buildFeatureLinkChanges>
): any {
  if (!featureId) {
    return null;
  }

  const { insertedLinks, deletedLinks } = changes;

  return {
    deletes: deletedLinks.map((feature) => ({
      source_instance_id: featureId,
      target_instance_id: feature.id
    })),
    inserts: insertedLinks.map((feature) => ({
      source_instance_id: featureId,
      target_instance_id: feature.id,
      link_type: 0,
      coupling_type: "LOOSE"
    }))
  };
}

function FeatureToFeatureLinksManagePanel(props: {
  featureId: string;
  title: string;
  showDependencyButton?: boolean;
}) {
  const { featureId, title, showDependencyButton = false } = props;

  const { tokenRetrievalState } = useContext(ProjectContext);
  const token = tokenRetrievalState?.token;

  const [inEditMode, setInEditMode] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedFeatures, setSelectedFeatures] = useState<any[]>([]);

  const {
    linkedFeatures,
    featuresList,
    saveLinks,
    isLoadingLinkedFeatures,
    isLoadingFeaturesList,
    isSavingLinks,
    linkedFeaturesError,
    resetFeaturesListStates
  } = useFeatureToFeatureLinks({
    token,
    featureInstanceId: featureId,
    featureTypeId: FeatureTypes.BID_PACKAGE,
    linkType: "child",
    loadLinkedFeatures: !inEditMode,
    loadFeaturesList: isModalOpen
  });

  useEffect(() => {
    setSelectedFeatures(linkedFeatures);
  }, [linkedFeatures]);

  const onCheckBoxAction = (data: { checked: boolean; feature: any }) => {
    setInEditMode(true);
    if (data.checked) {
      setSelectedFeatures([data.feature, ...selectedFeatures]);
    } else {
      setSelectedFeatures(
        selectedFeatures.filter((feature) => feature.id !== data.feature.id)
      );
    }
  };

  const onSave = async () => {
    const { insertedLinks, deletedLinks } = buildFeatureLinkChanges(
      selectedFeatures,
      linkedFeatures
    );

    const payload = generateFeatureLinkPayload(featureId, {
      insertedLinks,
      deletedLinks
    });

    const response = await saveLinks(payload);

    if (response.success) {
      setIsModalOpen(false);
      resetFeaturesListStates();
      setInEditMode(false);
      message.success("Bid package links updated successfully");
    }
  };

  const onUnlink = useCallback(
    (feature: any) => {
      setInEditMode(true);
      setSelectedFeatures(selectedFeatures.filter((f) => f.id !== feature.id));
    },
    [selectedFeatures]
  );

  const linkedFeaturesDOMForPanel = useMemo(() => {
    if ((!linkedFeatures && isLoadingLinkedFeatures) || linkedFeaturesError) {
      return null;
    }

    if (linkedFeatures?.length === 0) {
      return (
        <div className="w-full h-full flex items-center justify-center text-xs italic text-[#3B3B3BCC]">
          No bid packages linked
        </div>
      );
    }
    return (
      <div className="space-y-2">
        {linkedFeatures?.map((link: any) => {
          return (
            <FeatureLinkedItem
              key={link.id}
              number={link.number}
              title={link.title}
              sizeClass="text-xs"
            />
          );
        })}
      </div>
    );
  }, [linkedFeaturesError, linkedFeatures, isLoadingLinkedFeatures]);

  const linkedFeaturesDOMForDialog = useMemo(() => {
    if ((!selectedFeatures && isLoadingLinkedFeatures) || linkedFeaturesError) {
      return null;
    }

    if (selectedFeatures?.length === 0) {
      return (
        <div className="w-full h-full flex items-center justify-center text-xs italic text-[#3B3B3BCC] pt-14">
          No bid packages linked
        </div>
      );
    }
    return (
      <div className="space-y-2">
        {selectedFeatures?.map((link: any) => {
          return (
            <FeatureItem
              key={link.id}
              number={link.number}
              title={link.title}
              onUnlink={() => {
                onUnlink(link);
              }}
            />
          );
        })}
      </div>
    );
  }, [
    isLoadingLinkedFeatures,
    linkedFeaturesError,
    onUnlink,
    selectedFeatures
  ]);

  return (
    <>
      <div className="py-4 text-xs w-full h-full flex flex-col overflow-hidden">
        <div className="px-2.5 flex items-center justify-between">
          <div>{title}</div>
          <div>
            <Button
              type="link"
              size="small"
              className="text-xs ciq-button-1 h-[20px] px-0"
              onClick={() => {
                setTimeout(() => {
                  setIsModalOpen(true);
                });
              }}
            >
              Modify
              <LinkOutlined className="!ml-1" />
            </Button>
          </div>
        </div>
        <div className="px-2.5 grow mt-3 overflow-auto">
          {linkedFeaturesDOMForPanel}
        </div>
        {showDependencyButton && (
          <div className="px-2.5 pt-3 flex items-center justify-center">
            <ShowDependenciesVisualization designPackageId={featureId} />
          </div>
        )}
      </div>
      <FeatureLinkingDialog
        open={isModalOpen}
        headerComponent={
          <FeatureLinksDialogHeader
            onCancel={() => {
              setIsModalOpen(false);
              resetFeaturesListStates();
              setSelectedFeatures(linkedFeatures);
            }}
            onSave={onSave}
            disableSaveButton={!inEditMode}
            disableCancelButton={isSavingLinks}
            saveButtonLoading={isSavingLinks}
          />
        }
        lhsComponent={
          <div className="h-full max-h-full overflow-hidden flex flex-col">
            {selectedFeatures && selectedFeatures.length ? (
              <div className="my-5 text-xs px-4">
                Linked Bid Packages ({selectedFeatures?.length || 0})
              </div>
            ) : null}
            <div className="grow overflow-y-auto px-4 ">
              {linkedFeaturesDOMForDialog}
            </div>
          </div>
        }
        rhsComponent={
          <FeaturesGrid
            featuresList={featuresList}
            onCheckBoxAction={onCheckBoxAction}
            linkedFeatures={selectedFeatures}
            isLoadingFeaturesList={isLoadingFeaturesList}
          />
        }
      />
    </>
  );
}

export default FeatureToFeatureLinksManagePanel;
