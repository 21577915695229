import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import GCReviewersDropdown from "components/gc-reveiewer-dropdown";
import ResponsibleDesignFirmsDropdown from "components/responsible-design-firms";
import ResponsibleDesignerComponent from "components/responsible-designers";
import { UserParticipationType } from "constants/index";
import { useEffect } from "react";

export default function DesignPackageGeneralInfo(props: {
  featureInstanceData: any;
  actions: any;
}) {
  const { featureInstanceData, actions } = props;
  const [form] = Form.useForm();
  const selectedDesignFirmId = Form.useWatch("design_firm", form);

  useEffect(() => {
    const designFirm = featureInstanceData?.feature_company_participants?.length
      ? featureInstanceData?.feature_company_participants[0]?.vendor_id
      : "";

    const responsibleDesigner = featureInstanceData?.responsible_designer
      ?.length
      ? featureInstanceData?.responsible_designer[0]?.user?.id
      : "";

    const gcRepresentative =
      featureInstanceData?.feature_user_paticipants?.find(
        (participant: any) =>
          participant.user_participation_type_id ===
          UserParticipationType.GC_REPRESENTATIVE
      );

    form.setFieldsValue({
      description: featureInstanceData?.description,
      design_firm: designFirm,
      responsible_designer: responsibleDesigner,
      gc_representative: gcRepresentative?.user?.id
    });
  }, [featureInstanceData, form]);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item name="description" label="Description ">
        <TextArea
          rows={5}
          placeholder="Enter Description"
          onBlur={async (event: any) => {
            await actions.updateFeatureInstanceFields({
              description: event.target.value
            });
          }}
        />
      </Form.Item>
      <Form.Item label="Responsible Design Firm" name="design_firm">
        <ResponsibleDesignFirmsDropdown
          onChange={async (value: any) => {
            form.setFieldValue("responsible_designer", "");
            await actions.updateDesignFirm({
              design_firm: value
            });
          }}
        />
      </Form.Item>
      <Form.Item label="Responsible Designer" name="responsible_designer">
        <ResponsibleDesignerComponent
          onChange={async (value: any) => {
            await actions.updateFeatureUserParticipant({
              user_id: value,
              user_participation_type_id:
                UserParticipationType.RESPONSIBLE_DESIGNER
            });
          }}
          selectedDesignFirmId={selectedDesignFirmId}
        />
      </Form.Item>
      <Form.Item label="GC Representative" name="gc_representative">
        <GCReviewersDropdown
          onChange={async (value: any) => {
            await actions.updateFeatureUserParticipant({
              user_id: value,
              user_participation_type_id:
                UserParticipationType.GC_REPRESENTATIVE
            });
          }}
        />
      </Form.Item>
    </Form>
  );
}
