import { AgGridReact } from "ag-grid-react";
import { Button, notification } from "antd";
import CiqAgSearch from "components/ciq-ag-search";
import CiqGridViewWrapper from "components/ciq-grid-view-wrapper";
import { useRef, useState } from "react";
import { calculateLogPageStats } from "components/ciq-log-page-header";
import { newEntityNotificationMessage } from "entity-app/shared-components/new-entity-notification-message";
import { useParams } from "react-router";
import { FeatureTypes } from "entity-app/constants";
import { useDesignPackageAgGrid } from "./hooks/design-package-ag-grid-data";
import { FilterChipComponent } from "../../entity-app/shared-components/log-render-components/filter-chip-component";

import type { FilterChipComponentRef } from "../../entity-app/shared-components/log-render-components/filter-chip-component";
import CreateDesignPackageComponent from "./components/create-design-package";
import { useDesignPackageData } from "./hooks/design-package-data";

const featureKey = "design-packages"; // URL

function DesignPackageLogPage() {
  const gridRef = useRef<AgGridReact<any>>(null);
  const pageData = useDesignPackageData();
  const agGridData = useDesignPackageAgGrid({ featureKey });
  const filterChipRef = useRef<FilterChipComponentRef>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { projectId } = useParams() as any;

  const headerItems = [
    <CiqAgSearch key="DesignPackageLog_search" gridRef={gridRef} />,
    <FilterChipComponent
      key="DesignPackageLog_FilterChip"
      columnDefs={agGridData.columnDefs}
      gridRef={gridRef}
      ref={filterChipRef}
    />,
    <Button
      key="DesignPackageLog_new"
      onClick={() => {
        setShowDrawer(!showDrawer);
      }}
    >
      New Design Package
    </Button>,
    <Button key="DesignPackageLog_importLog" disabled>
      Import Log
    </Button>,
    <Button key="DesignPackageLog_export" disabled>
      Export
    </Button>
  ];

  const [statsData, setStatsData] = useState<{
    displayCount: number;
    filterName: undefined | string;
  }>();

  const openNotification = (id: string, title: string) => {
    api.open(
      newEntityNotificationMessage({
        projectId,
        id,
        title,
        featureKey
      })
    );
  };

  return (
    <>
      {contextHolder}
      <CiqGridViewWrapper
        headerInfo={{
          entityName: "DesignPackageLog",
          titleParam: {
            title: "Design Packages",
            totalCount:
              pageData.designPackageList?.design_package_list_view?.length,
            filterStats: statsData
          },
          gridRef,
          items: headerItems
        }}
        gridInfo={{
          gridRef,
          columnDefs: agGridData.columnDefs,
          rowData: pageData.designPackageList?.design_package_list_view,
          defaultColDef: agGridData.defaultColDef,
          rowHeight: 62,
          onFilterChanged: () => {
            filterChipRef.current?.onFilterApplied();
            setStatsData(
              calculateLogPageStats({
                gridRef,
                featureTypeId: FeatureTypes.DESIGN_PACKAGE
              })
            );
          }
        }}
      />
      <CreateDesignPackageComponent
        setDrawerOpen={setShowDrawer}
        showDrawerOpen={showDrawer}
        modelTitle="Create Design Package"
        onDesignPackageCreated={(title: string, id: string) => {
          setShowDrawer(false);
          openNotification(id, title);
          pageData.refetchDesignPackageList();
        }}
      />
    </>
  );
}

export default DesignPackageLogPage;
