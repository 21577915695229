/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-plusplus */
import { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-enterprise";
import { Link } from "react-router-dom";
import GoverningActivityCellRenderer from "../governing-activity-cell-renderer/governing-activity-cell-renderer";
import MaterialScheduleCellRenderer from "./material-schedule-cell-renderer/material-schedule-cell-renderer";
import { headerHeight, maxRowCount, rowHeight } from "../utils";

function MaterialScheduleImpact(props: {
  linkedMaterials: [];
  allSubmittalDateBlock: [];
  isDeleted: boolean;
}) {
  const { linkedMaterials, allSubmittalDateBlock, isDeleted } = props;
  const [height, setHeight] = useState(0);
  const [isGridReady, setGridReady] = useState(false);
  const gridRef = useRef<AgGridReact<any>>(null);

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: "Impacted Materials",
        children: [
          {
            headerName: "Title",
            headerTooltip: "TITLE",
            valueGetter: (param: any) => {
              if (
                allSubmittalDateBlock &&
                param.data?.MDB?.old?.implicit &&
                param.data?.linked_submittals?.length > 0
              ) {
                const linkedSubmittals = param.data?.linked_submittals;
                const sdb: any = allSubmittalDateBlock[linkedSubmittals[0]];
                return `N/A | Material for Submittal ${
                  sdb?.old?.submittal_sequence_id || ""
                }`;
              }
              return `${param.data?.MDB?.old?.material_sequence_id} | ${param.data?.MDB?.old?.name}`;
            },
            sort: "asc",
            tooltipValueGetter: ({ value }: any) => value,
            cellRenderer: ({ value, data }: any) => {
              return data?.MDB?.old?.implicit ? (
                <span>{value}</span>
              ) : (
                <Link
                  to={{
                    pathname: `materials/${data?.material_id}`
                  }}
                  target="_blank"
                >
                  {value}
                </Link>
              );
            }
          },
          {
            headerName: "First Milestone",
            headerTooltip: "FIRST MILESTONE",
            field: "material_PO_date",
            minwidth: 150,
            cellRenderer: MaterialScheduleCellRenderer,
            cellRendererParams: { isDeleted }
          },
          // {
          //   headerName: "Order Duration",
          //   headerTooltip: "ORDER DURATION",
          //   field: "material_PO_fabrication_offset",
          //   valueGetter: (param: any) => {
          //     return `${
          //       param.data?.MDB?.old?.material_PO_fabrication_offset || ""
          //     }  Day(s)`;
          //   }
          // },
          // {
          //   headerName: "Fabrication Start",
          //   headerTooltip: "FABRICATION START",
          //   field: "fabrication_start_date",
          //   cellRenderer: MaterialScheduleCellRenderer,
          //   cellRendererParams: { isDeleted }
          // },
          // {
          //   headerName: "Fabrication Duration",
          //   headerTooltip: "FABRICATION DURATION",
          //   field: "fabrication_duration_offset",
          //   valueGetter: (param: any) => {
          //     return `${
          //       param.data?.MDB?.old?.fabrication_duration_offset || ""
          //     }  Day(s)`;
          //   }
          // },
          // {
          //   headerName: "Shipment",
          //   headerTooltip: "SHIPMENT",
          //   field: "shipment_date",
          //   cellRenderer: MaterialScheduleCellRenderer,
          //   cellRendererParams: { isDeleted }
          // },
          // {
          //   headerName: "Delivery Time",
          //   headerTooltip: "DEKIVERY TIME",
          //   field: "lead_time_offset",
          //   valueGetter: (param: any) => {
          //     return `${param.data?.MDB?.old?.lead_time_offset || ""}  Day(s)`;
          //   }
          // },
          {
            headerName: "Last Milestone",
            headerTooltip: "LAST MILESTONE",
            field: "ROJ_date",
            cellRenderer: MaterialScheduleCellRenderer,
            cellRendererParams: { isDeleted }
          },
          // {
          //   headerName: "On-site Storage",
          //   headerTooltip: "ON-SITE STORAGE",
          //   field: "on_site_storage_offset",
          //   valueGetter: (param: any) => {
          //     return `${
          //       param.data?.MDB?.old?.on_site_storage_offset || ""
          //     }  Day(s)`;
          //   }
          // },
          {
            headerName: "Governing Task",
            headerTooltip: "GOVERNING TASK",
            field: "governing_activity",
            cellRenderer: GoverningActivityCellRenderer,
            cellRendererParams: { isDeleted },
            width: 200
          }
        ]
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: false,
      filter: true,
      resizable: true,
      editable: false,
      autoHeight: true,
      menuTabs: []
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  const getRowHeight = () => rowHeight;

  const setRowHeight = () => {
    const materialCount = linkedMaterials?.length ?? 0;
    const minCount = Math.min(materialCount, maxRowCount);

    const viewHeight: number = minCount * rowHeight + headerHeight;
    setHeight(viewHeight);
    return viewHeight;
  };

  return (
    <div
      style={{ width: "100%", height }}
      className="ag-theme-alpine inner-table"
    >
      <div className="uppercase tracking-widest inline-block text-[11px] font-bold text-[#888] mb-1">
        Impacted materials
      </div>
      <AgGridReact
        ref={gridRef}
        rowData={linkedMaterials!}
        onGridReady={() => {
          setGridReady(true);
          gridRef.current?.api.sizeColumnsToFit();
          setRowHeight();
        }}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        headerHeight={headerHeight}
        groupHeaderHeight={0}
        getRowHeight={getRowHeight}
        suppressDragLeaveHidesColumns
        tooltipShowDelay={0}
        tooltipHideDelay={2000}
      />
    </div>
  );
}

export default MaterialScheduleImpact;
