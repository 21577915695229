import { Tooltip } from "antd";
import { RiskLevelType } from "components/date-block/models";
import { TFeatureWorkflowInstance } from "entity-app/models";
import { EWorkflowStatusDataBlock } from "../../../../../constants";
import EntityRiskThresholdSetting from "./entity-risk-threshold-setting";

function EntityRiskStatementView(props: {
  featureWorkflowInstance: TFeatureWorkflowInstance;
  actions: { updateFeatureWorkflowInstance: Function };
}) {
  const { featureWorkflowInstance: wfInstance, actions } = props;
  if (!wfInstance) return null;

  const isCompletedWorkflow =
    wfInstance.workflow_status === EWorkflowStatusDataBlock.WF_COMPLETED;
  if (isCompletedWorkflow)
    return (
      <div className="flex text-sm text-[#3B3B3B]">
        {wfInstance.risk_assessment}
      </div>
    );

  return (
    <div className="flex space-x-2 items-center text-[#3B3B3B] text-sm font-medium">
      <div>Risk:</div>
      <Tooltip
        title={wfInstance.risk_assessment}
        className={
          wfInstance.risk_level === RiskLevelType.High
            ? "date-block-risk-high cursor-default font-semibold uppercase"
            : "cursor-default font-semibold uppercase"
        }
        placement="topRight"
      >
        {wfInstance.risk_level}
      </Tooltip>
      <EntityRiskThresholdSetting
        featureTypeId={wfInstance.feature_type_id}
        threshold={wfInstance}
        actions={actions}
      />
    </div>
  );
}

export default EntityRiskStatementView;
