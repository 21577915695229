const FEATURE_USER_PARTICIPATION_TYPE = `
  FEATURE_USER_PARTICIPATION_TYPE {
    feature_user_participation_type {
      id
      name
    }
  }
`;

const FEATURE_COMPANY_PARTICIPATION_TYPE = `
  FEATURE_COMPANY_PARTICIPATION_TYPE {
    id
    name
  }
`;

const FEATURE_INSTANCE_BY_PK = `
  FEATURE_INSTANCE_BY_PK($featureId: uuid!) {
    feature_instance_by_pk(id: $featureId) {
      id
      title
      description
      external_source_id
      created_at
      created_by_user {
        email
        first_name
        last_name
      }
    }
  }
`;

const BID_PACKAGE_DATA_FOR_FEATURE_INSTANCE_BY_PK = `
  BID_PACKAGE_DATA_FOR_FEATURE_INSTANCE_BY_PK($featureId: uuid!) {
    feature_instance_by_pk(id: $featureId) {
      feature_bid_package {
        no_of_bidders
        wave
      }
    }
  }
`;

const SPEC_SECTIONS_LIST_FOR_FEATURE_INSTANCE_BY_PK = `
  SPEC_SECTIONS_LIST_FOR_FEATURE_INSTANCE_BY_PK($featureId: uuid!) {
    feature_instance_spec_section(where: {feature_instance_id: {_eq: $featureId}}) {
      spec_section_id
      spec_section {
        section_name
        section_number
        specification_id
        title
        description
        division_name
        division_no
      }
    }
  }
`;

const FEATURE_INSTANCE_DETAILS_BY_PK = `
  FEATURE_INSTANCE_BY_PK_DETAILS_PACKAGE($featureId: uuid!) {
    feature_instance_by_pk(id: $featureId) {
      id
      title
      description
      number
      external_source_id
      created_at
      created_by_user {
        email
        first_name
        last_name
      }
      feature_type_id
      feature {
        id
        feature
        show_float
      }
      feature_bid_package {
        no_of_bidders
        wave
        awarded_value
        estimated_value
      }
      feature_company_participants {
        vendor_id
        instance_id
        company_participation_type_id
        vendor {
          name
        }
      }
      responsible_designer: feature_user_paticipants(where: {user_participation_type_id: {_eq: 4}}) {
      user {
        first_name
        last_name
        email
        id
      }
    }
      feature_user_paticipants {
        user {
          id
          email
          first_name
          last_name
          status_id
        }
        user_participation_type_id
      }
      feature_instance_spec_sections {
        bid_scope
        spec_section {
          id
          title
          description
          section_name
          section_number
          specification_id
        }
      }
      feature_workflow_instance {
        id
        feature_type_id
        workflow_status
        milestone_state
        float
        high_risk_threshold
        low_risk_threshold
        risk_level
        risk_assessment
        manual_entry_date
        workflow_template_id
        override_project_threshold
      }
      workflow_instance_milestones {
        id
        sequence_no
        name
        actual_date
        planned_date
        status
        milestone_action
        feature_workflow_instance_offsets_as_src {
          id
          name
          duration
        }
      }
    }
  }
`;

const FEATURE_ATTACHMENTS = `
  attachments($where: attachments_bool_exp!) {
    attachments(where: $where) {
      file_name
      file_size
      submittal_id
      material_id
      id
      blob_key
      version
      uploaded
      created_at
      file_type
      reference_id
      submittal_status_history {
        workflow_status
      }
      created_by_user {
        email
        first_name
        last_name
        project_users {
          subscription {
            name
          }
          subscription_vendor {
            name
          }
        }
      }
      versions {
        id
        blob_key
        version
        uploaded
        file_type
        file_name
        created_at
        submittal_id
        submittal_status_history {
          workflow_status
        }
        created_by_user {
          email
          first_name
          last_name
          project_users {
            subscription {
              name
            }
            subscription_vendor {
              name
            }
          }
        }
      }
      on_behalf_of_user_id
      on_behalf_of
      attachments_user {
        first_name
        id
        last_name
        email
      }
    }
  }
`;

const GET_SCHEDULE_LIST = `
  GET_SCHEDULE_LIST {
    schedule_import_details(
      order_by: { created_at: desc }
      where: { import_log: { import_type: { _eq: SCHEDULE } } }
    ) {
      id
      import_log_id
      is_active
      schedule_name
      schedule_date
      schedule_type
      activated_at
      created_at
      import_log {
        id
        resolved_values_s3_prefix
        created_at
        created_by_user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

const GET_SCHEDULE_ACTIVITIES = `
  GET_SCHEDULE_ACTIVITIES {
    gantt_tasks {
      id
      type
      text
      duration
      end_date
      start_date
      source_task_id
      import_log_id
      parent
    }
  }
`;

const GET_FEATURE_INSTANCE_PLANNING_DATA = `
  GET_FEATURE_INSTANCE_PLANNING_DATA($featureId: uuid!) {
    feature_instance_by_pk(id: $featureId) {
      id
      title
      feature_type_id
      links_as_source {
        target_feature_instance {
          id
          title
          feature_type_id
          workflow_instance_offsets {
            id
            name
            duration
          }
          workflow_instance_milestones {
            id
            name
            planned_date
            sequence_no
            hidden
          }
          links_as_source {
            id
            target_feature_instance {
              id
              title
              feature_type_id
              workflow_instance_offsets {
                name
                duration
                id
              }
              workflow_instance_milestones {
                id
                name
                planned_date
                sequence_no
                hidden
              }
              links_as_source {
                id
                link_type
                governing_link
                target_gantt_task {
                  id
                  start_date
                  end_date
                  source_task_id
                  type
                  text
                }
              }
              links_as_source {
                id
                governing_link
                target_feature_instance {
                  id
                  project_id
                  feature_type_id
                  title
                  feature_workflow_instance {
                    id
                    manual_entry_date
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_LINKED_ACTIVITIES = `
  GET_LINKED_ACTIVITIES($featureInstanceId: uuid!) {
  feature_instance(where: {id: {_eq: $featureInstanceId}}) {
    links_as_source {
      id
      link_type
      created_at
      target_gantt_task {
        id
        start_date
        end_date
        source_task_id
        type
        text
      }
    }
  }
}
`;

const GET_FEATURE_TO_FEATURE_LINKS = `
  GET_FEATURE_TO_FEATURE_LINKS($featureInstanceId: uuid!, $featureTypeId: Int) {
  feature_linking(where: {target_instance_id: {_eq: $featureInstanceId }, source_feature_instance: {feature_type_id: {_eq: $featureTypeId}}}) {
    source_feature_instance {
      created_at
      created_by
      description
      external_source_id
      feature_type_id
      updated_at
      updated_by
      title
      id
      number
    }
    link_type
    source_instance_id
  }
}
`;

const GET_FEATURE_COMMENTS = `
GetComments($where: comment_bool_exp) {
  comment(where: $where, order_by: {created_at: desc}) {
    id
    feature_instance_id
    description
    attachments {
      id
      file_name
      file_type
      file_size
      blob_key
    }
    workflow_step
    workflow_template_id
    created_at
    created_by
    created_by_user {
      email
      first_name
      last_name
      project_users {
        subscription {
          name
        }
      }
    }
  }
}
`;

const FEATURE_LIST_BY_FEATURE_TYPE_ID = `
  FEATURE_LIST_BY_FEATURE_TYPE_ID($featureTypeId: Int) {
    feature_instance(where: {feature_type_id: {_eq: $featureTypeId}}) {
    id
    number
    title
    description
  }
}
`;

const GET_CHILD_FEATURE_LINKS = `
  GET_CHILD_FEATURE_LINKS($featureInstanceId: uuid!, $featureTypeId: Int) {
  feature_linking(where: {source_instance_id: {_eq: $featureInstanceId }, target_feature_instance: {feature_type_id: {_eq: $featureTypeId}}}) {
    target_feature_instance {
      id
      created_at
      created_by
      description
      external_source_id
      feature_type_id
      number
      updated_at
      updated_by
      title
    }
    link_type
  }
}
`;

const GET_PARENT_FEATURE_LINKS = `
  GET_PARENT_FEATURE_LINKS($featureInstanceId: uuid!, $featureTypeId: Int) {
  feature_linking(where: {target_instance_id: {_eq: $featureInstanceId }, source_feature_instance: {feature_type_id: {_eq: $featureTypeId}}}) {
    source_feature_instance {
      id
      created_at
      created_by
      description
      external_source_id
      feature_type_id
      number
      updated_at
      updated_by
      title
    }
    link_type
  }
}
`;

const CHECK_FEATURE_TITLE_EXISTS = `
  CHECK_FEATURE_TITLE_EXISTS($title: String!, $feature_type: String!) {
  feature_instance(where: {_and: [{feature: {feature: {_eq: $feature_type}}}, {title: {_eq: $title}}]}) {
    id
  }
}
`;

export {
  FEATURE_USER_PARTICIPATION_TYPE,
  FEATURE_COMPANY_PARTICIPATION_TYPE,
  FEATURE_INSTANCE_BY_PK,
  BID_PACKAGE_DATA_FOR_FEATURE_INSTANCE_BY_PK,
  SPEC_SECTIONS_LIST_FOR_FEATURE_INSTANCE_BY_PK,
  FEATURE_INSTANCE_DETAILS_BY_PK,
  FEATURE_ATTACHMENTS,
  GET_SCHEDULE_LIST,
  GET_SCHEDULE_ACTIVITIES,
  GET_FEATURE_INSTANCE_PLANNING_DATA,
  GET_LINKED_ACTIVITIES,
  GET_FEATURE_TO_FEATURE_LINKS,
  GET_FEATURE_COMMENTS,
  FEATURE_LIST_BY_FEATURE_TYPE_ID,
  GET_CHILD_FEATURE_LINKS,
  GET_PARENT_FEATURE_LINKS,
  CHECK_FEATURE_TITLE_EXISTS
};
