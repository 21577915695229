import { EditOutlined } from "@ant-design/icons";
import "./inline-edit-title.scss";
import { Typography } from "antd";

const { Paragraph } = Typography;

function InlineEditTitle(props: {
  isPermissionToEdit: boolean;
  headerInfo: any;
  updateHeaderInfo: any;
}) {
  const { isPermissionToEdit, headerInfo, updateHeaderInfo } = props;

  return (
    <div className="flex items-center gap-x-7">
      <Paragraph
        className="!my-0 !font-normal text-two !text-black max-w-xs"
        editable={
          isPermissionToEdit
            ? {
                icon: <EditOutlined className="ml-1 text-[#0000007F]" />,
                tooltip: null,
                onChange: (value) =>
                  updateHeaderInfo({
                    title: headerInfo?.featureHeaderTitle,
                    number: value
                  }),
                maxLength: 1000
              }
            : false
        }
      >
        {headerInfo?.featureHeaderNumber}
      </Paragraph>
      <Paragraph
        className="!my-0 !font-normal text-two !text-black max-w-xs"
        editable={
          isPermissionToEdit
            ? {
                icon: <EditOutlined className="ml-1 text-[#0000007F]" />,
                tooltip: null,
                onChange: (value) =>
                  updateHeaderInfo({
                    title: value,
                    number: headerInfo?.featureHeaderNumber
                  }),
                maxLength: 1000
              }
            : false
        }
      >
        {headerInfo?.featureHeaderTitle}
      </Paragraph>
    </div>
  );
}

export default InlineEditTitle;
