import PlusAddIcon from "components/svg-icons/plus-add-icon";
import DateInputIcon from "components/svg-icons/date-icon";
import { DeleteOutlined } from "@ant-design/icons";
import { Modal, Space } from "antd";
import { useState } from "react";
import { getDaysText } from "utils/utils";
import { TEditMilestone } from "./model";
import EditableInput from "./components/editable-input";
import AddMilestonePanel from "./components/add-milestone-panel";
import { VRules } from "./validation-rules";
import EditablePopover from "./components/editable-popover";
import DeleteConfirmModel from "./components/delete-confirm-modal";
import AddOffsetPanel from "./components/add-offset-panel";

function MilestoneEditor({
  milestone,
  index,
  allMilestones,
  setMilestones,
  disable = false,
  floatState
}: {
  milestone: TEditMilestone;
  index: number;
  allMilestones: TEditMilestone[];
  setMilestones: React.Dispatch<React.SetStateAction<TEditMilestone[]>>;
  disable?: boolean;
  floatState: { setFloat: (value: number) => void; float: number };
}) {
  const isFirst = index === 0;
  const isLast = index === allMilestones.length - 1;

  /// //////////////////////////////////////////////////////////////

  const setCurrentMilestone = (newMilestone: TEditMilestone) => {
    const tempAllMileStones = [...allMilestones];
    tempAllMileStones.splice(index, 1, newMilestone);
    setMilestones(tempAllMileStones);
  };

  // Model to add new Milestone
  const [openAddMilestonePanel, setOpenAddMilestonePanel] = useState(false);
  const onOpenMilestoneModel = () => {
    setOpenAddMilestonePanel(true);
  };
  const onAddMilestone = (newMilestone: TEditMilestone) => {
    const tempAllMileStones = [...allMilestones];
    tempAllMileStones.splice(index, 0, newMilestone);
    console.log(tempAllMileStones);
    setMilestones(tempAllMileStones);
  };
  const addMilestoneModel = openAddMilestonePanel && (
    <AddMilestonePanel
      isOpen={openAddMilestonePanel}
      setOpen={setOpenAddMilestonePanel}
      milestones={allMilestones}
      onAdd={onAddMilestone}
    />
  );

  // Add Last Offset Data
  const [openAddOffset, setOpenOffset] = useState(false);
  const onOpenOffsetModel = () => {
    setOpenOffset(true);
  };
  const onAddOffset = (data: { duration: number; offsetName: string }) => {
    const tempMilestone = {
      ...milestone,
      ...data
    };
    setCurrentMilestone(tempMilestone);
  };
  const addOffsetModel = openAddOffset && (
    <AddOffsetPanel
      isOpen={openAddOffset}
      setOpen={setOpenOffset}
      milestones={allMilestones}
      onAdd={onAddOffset}
    />
  );

  // Remove selected milestone
  const [removingMilestone, setRemovingMilestone] = useState<TEditMilestone>();
  const onOpenDeleteMilestonModel = () => setRemovingMilestone(milestone);
  const onRemoveMilestone = (milestoneId: string) => {
    const tempAllMileStones = allMilestones.filter((m) => m.id !== milestoneId);
    setMilestones(tempAllMileStones);
  };
  const removeMilestoneModel = removingMilestone && (
    <DeleteConfirmModel
      milestone={removingMilestone}
      setOpen={setRemovingMilestone}
      onConfirm={onRemoveMilestone}
    />
  );

  // Remove Last Offset
  const onRemoveOffset = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this duration?",
      onOk() {
        const tempMilestone = {
          ...milestone,
          offsetName: null,
          duration: null
        };
        setCurrentMilestone(tempMilestone);
      },
      okButtonProps: { className: "w-24" },
      cancelButtonProps: {
        className: "w-24"
      },
      okText: "Yes"
    });
  };

  // Rename offset Name of milestone
  const onRenameOffsetName = (offsetName: string) => {
    const tempMilestone = { ...milestone, offsetName };
    setCurrentMilestone(tempMilestone);
  };

  // Update offset duration of milestone
  const onDurationUpdate = (duration: number) => {
    const tempMilestone = { ...milestone, duration: Number(duration) };
    setCurrentMilestone(tempMilestone);
  };

  // Rename the name of milestone
  const onRenameMilestoneName = (name: string) => {
    const tempMilestone = { ...milestone, name };
    setCurrentMilestone(tempMilestone);
  };

  // Rename Milestone Action
  const onRenameMilestoneAction = (milestone_action: string) => {
    const tempMilestone = {
      ...milestone,
      milestone_action
    };
    setCurrentMilestone(tempMilestone);
  };

  const onFloatUpdate = (duration: string) => {
    floatState.setFloat(Number(duration));
  };

  const actionInfoModelDiv = (
    <EditablePopover
      placeholder="Write about the milestone here.."
      disable={disable}
      message={milestone.milestone_action}
      onSubmit={onRenameMilestoneAction}
    />
  );

  const offsetDiv = milestone.offsetName && (
    <div className="w-80 h-16 shrink-0 flex-col space-y-2 justify-end">
      <div className="w-full  flex justify-end">
        <EditableInput
          disable={disable}
          className="text-milestone"
          classNameEdit="text-edit-offset"
          input={milestone.offsetName}
          onSubmit={onRenameOffsetName}
          rules={VRules.OffsetName(
            allMilestones.filter((x) => x.id !== milestone.id)
          )}
        />
      </div>
      <div className="w-full flex justify-end">
        <EditableInput
          disable={disable}
          className="text-milestone"
          classNameEdit="text-edit-offset"
          inputText={`(${milestone.duration} ${getDaysText(
            milestone.duration
          )})`}
          input={milestone.duration}
          onSubmit={onDurationUpdate}
          rules={VRules.Duration}
        />
      </div>
    </div>
  );

  const lastOffsetDiv = isLast && (
    <div className="flex">
      <div className="w-72 h-16 shrink-0 flex-col space-y-1">
        <div className="w-full flex justify-end">
          <EditableInput
            disable
            className="text-milestone"
            classNameEdit="text-edit-offset"
            input="Material Float"
          />
        </div>
        <div className="w-full flex justify-end">
          <EditableInput
            disable={disable}
            className="text-milestone"
            classNameEdit="text-edit-offset"
            inputText={`(${floatState.float} ${getDaysText(floatState.float)})`}
            input={floatState.float}
            onSubmit={onFloatUpdate}
            rules={VRules.Duration}
          />
        </div>
      </div>
      {offsetDiv}
    </div>
  );

  const horizontalLineInitialPaddingDiv = isFirst ? (
    <div className="bl-left-emplty" />
  ) : (
    <div className="bl-line-left " />
  );
  const horizontalLineRectangleDiv = (
    <div className="flex items-center">
      <div className="bl-line" /> <div className="bl-rect" />
    </div>
  );
  const horizontalLineLastRectangleDiv = milestone.offsetName ? (
    <div className="flex items-center">
      <div className="bl-line" />
      <div className="bl-rect bg-gray-300" />
      <div className="relative w-48">
        <div className="bl-line !w-48" />
        <div className="absolute left-20 -top-6">
          <DateInputIcon />
        </div>
      </div>
      <div className="bl-rect flex justify-end items-center">
        {!disable && (
          <DeleteOutlined
            className="cursor-pointer px-1"
            onClick={onRemoveOffset}
          />
        )}
      </div>
      <div className="bl-left-emplty" />
    </div>
  ) : (
    <div className="flex items-center">
      <div className="bl-line" />
      <div className="bl-rect bg-gray-300" />
      <div className="relative">
        <div className="bl-line" />
        <div className="absolute left-5 -top-6">
          <DateInputIcon className="cursor-pointer p-[1px]" />
        </div>
      </div>
      {!disable && (
        <Space className="-ml-1 mt-1" onClick={onOpenOffsetModel}>
          <PlusAddIcon className="cursor-pointer" />
        </Space>
      )}
      <div className="bl-left-emplty" />
    </div>
  );
  const horizontalLineDiv = (
    <div className="flex w-full items-center">
      {horizontalLineInitialPaddingDiv}
      <div className="bl-circle" />
      {isLast ? horizontalLineLastRectangleDiv : horizontalLineRectangleDiv}
    </div>
  );

  const addMilestoneDiv = (
    <div className="flex w-6 items-center pl-1">
      {!disable && allMilestones.length < 10 && (
        <Space onClick={onOpenMilestoneModel}>
          <PlusAddIcon className="cursor-pointer" />
        </Space>
      )}
    </div>
  );

  const milestoneHeaderDiv = (
    <div className="flex p-2 h-12 border-solid border-0 border-b border-inherit justify-between">
      <EditableInput
        disable={disable}
        className="text-milestone"
        classNameEdit="text-xs max-w-[130px]"
        input={milestone.name}
        onSubmit={onRenameMilestoneName}
        rules={VRules.MilestoneName(
          allMilestones.filter((x) => x.id !== milestone.id)
        )}
      />
      <div className="flex space-x-2 items-center">
        {actionInfoModelDiv}
        {!disable && !isLast && allMilestones.length > 2 && (
          <DeleteOutlined
            className="cursor-pointer"
            onClick={onOpenDeleteMilestonModel}
          />
        )}
      </div>
    </div>
  );

  const milestoneBottomDiv = (
    <div className="p-2 h-16" style={{ width: "230px" }} />
  );

  return (
    <div className="pb-5 shrink-0 flex-col relative">
      <div className="h-28">
        <div className="w-80 absolute left-10 top-4 flex-col space-y-5">
          {isLast ? lastOffsetDiv : offsetDiv}
          {horizontalLineDiv}
        </div>
      </div>

      <div className="w-full flex space-x-3 items-center px-1 mt-12">
        {addMilestoneDiv}
        <div className="w-full flex-col border border-solid ">
          {milestoneHeaderDiv}
          {milestoneBottomDiv}
        </div>
      </div>
      {addMilestoneModel}
      {removeMilestoneModel}
      {addOffsetModel}
    </div>
  );
}

export default MilestoneEditor;
