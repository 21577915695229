import { AgGridReact } from "ag-grid-react";
import { Button, notification } from "antd";
import CiqAgSearch from "components/ciq-ag-search";
import CiqGridViewWrapper from "components/ciq-grid-view-wrapper";
import { useCallback, useContext, useRef, useState } from "react";
import { calculateLogPageStats } from "components/ciq-log-page-header";
import { newEntityNotificationMessage } from "entity-app/shared-components/new-entity-notification-message";
import { useParams } from "react-router";
import {
  FilterChipComponent,
  FilterChipComponentRef
} from "entity-app/shared-components/log-render-components/filter-chip-component";
import { AppContext } from "context/AppProvider";
import { applyBidPackagesLogFilters } from "utils/ag-grid-programmatic-filtering";
import { resetGridFilters } from "entity-app/utils/reset-grid-filter-util";
import { FeatureTypes } from "entity-app/constants";
import { usePreconstrutionData } from "./hooks/preconstruction-data";
import { usePreconstrutionAgGrid } from "./hooks/preconstruction-ag-grid-data";
import CreateBidPackageComponent from "./components/create-bid-package";

const featureKey = "bid-packages"; // URL

function PreconstructionLogPage() {
  const gridRef = useRef<AgGridReact<any>>(null);
  const pageData = usePreconstrutionData();
  const agGridData = usePreconstrutionAgGrid({ featureKey });
  const filterChipRef = useRef<FilterChipComponentRef>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { projectId } = useParams() as any;
  const { bidPackageListFilter, setBidPackageListFilter } =
    useContext(AppContext);

  const headerItems = () => {
    const onAllBidPackagesClick = () => {
      resetGridFilters({
        setListFilter: setBidPackageListFilter,
        api: gridRef.current?.api
      });
    };

    const menus = [
      <CiqAgSearch key="PreconstructionLogPage_search" gridRef={gridRef} />,

      <Button
        key="PreconstructionLogPage_new"
        onClick={() => {
          setShowDrawer(!showDrawer);
        }}
      >
        New Bid Package
      </Button>,
      <Button key="PreconstructionLogPage_importLog" disabled>
        Import Log
      </Button>,
      <Button key="PreconstructionLogPage_export" disabled>
        Export
      </Button>
    ];

    if (bidPackageListFilter) {
      menus.splice(
        1,
        0,
        <Button
          key="PreconstructionLogPage_AllBidPackages"
          onClick={() => onAllBidPackagesClick()}
        >
          All Bid Packages
        </Button>
      );
    } else {
      menus.splice(
        1,
        0,
        <FilterChipComponent
          key="PreconstructionLogPage_FilterChip"
          columnDefs={agGridData.columnDefs}
          gridRef={gridRef}
          ref={filterChipRef}
        />
      );
    }

    return menus;
  };

  const [statsData, setStatsData] = useState<{
    displayCount: number;
    filterName: undefined | string;
  }>();

  const openNotification = (id: string, title: string) => {
    api.open(
      newEntityNotificationMessage({
        projectId,
        id,
        title,
        featureKey
      })
    );
  };

  const onFirstDataRendered = useCallback(() => {
    if (bidPackageListFilter) {
      applyBidPackagesLogFilters({ gridRef, bidPackageListFilter });
    }
  }, [bidPackageListFilter]);

  return (
    <>
      {contextHolder}
      <CiqGridViewWrapper
        headerInfo={{
          entityName: "PreconstructionLogPage",
          titleParam: {
            title: "Bid Packages",
            totalCount: pageData.bidPackageList?.bid_package_list_view?.length,
            filterStats: statsData
          },
          gridRef,
          items: headerItems()
        }}
        gridInfo={{
          gridRef,
          columnDefs: agGridData.columnDefs,
          rowData: pageData.bidPackageList?.bid_package_list_view,
          defaultColDef: agGridData.defaultColDef,
          rowHeight: 62,
          onFilterChanged: () => {
            filterChipRef.current?.onFilterApplied();
            setStatsData(
              calculateLogPageStats({
                gridRef,
                ListFilter: bidPackageListFilter,
                featureTypeId: FeatureTypes.BID_PACKAGE
              })
            );
          },
          onFirstDataRendered
        }}
      />
      {showDrawer && (
        <CreateBidPackageComponent
          setDrawerOpen={setShowDrawer}
          showDrawerOpen={showDrawer}
          modelTitle="Create Bid Package"
          onBidPackageCreated={(title: string, id: string) => {
            setShowDrawer(false);
            openNotification(id, title);
            pageData.refetchBidPackageList();
          }}
        />
      )}
    </>
  );
}

export default PreconstructionLogPage;
